import React from 'react';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { uploadTime } from '../../helpers';

const Details = ({ video }) => {
  return (
    <div className="video-details-container">
      <div className="video-box">
        <div className="video">
          <iframe
            title={video.externalVideoId}
            frameBorder="0"
            // width="550"
            // height="400"
            className='player'
            src={`https://www.dailymotion.com/embed/video/${video.externalVideoId}`}
            allowFullScreen
            allow="autoplay; fullscreen"
          />
        </div>
        <div className="details">
          <div className="top-div">
            <div className="ch_title-category">
              <p className="channel-name">
                {video.channelName}
                <DoneIcon className="done-icon" />
              </p>
              <div className="ch_title-category-category-block">{video.category}</div>
            </div>
            <div className="title-desc">
              <h1 className="title">{video.title}</h1>
              <h1 className="desc">{video.description}</h1>
            </div>
          </div>
          <div className="bottom-div">
            <div className="tags-views-upload">
              <h1 className="tags">{video.tags.slice(0, 5).map((tag) => `#` + tag)}</h1>
              <h1 className="views">
                <VisibilityIcon className="views-icon" />
                {video.totalViews}
              </h1>
              <h1 className="upload">{uploadTime(video.uploadTime)}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
