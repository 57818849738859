import React from 'react';
import Close from './close.svg';
import '../../styles/components/downloadPopUp.css';

const DownloadPopUp = ({onClose}) => {

  return (
    <div className='wrapper-pop-up'>
      <div className='layout'>
        <img className='close' src={Close} alt="close" onClick={onClose}/>
        <h1 className='title'>Please download <br/> the <span className='yealow'>Remittv Android App</span>! </h1>

        <p className='content-dialog'>
          Remittv is a Crypto App. Remittv believes everyone should earn from their Creative work, with no barriers or rules, so you earn Crypto from the 1st view.
        </p>

        <ul className='content_list-pop_up'>
          <li><div className='list_point'/><div>Remittv gifts coins to Creators and viewers for free</div></li>
          <li><div className='list_point'/><div>Coins are tipped to Creators so they earn Crypto Currency</div></li>
          <li><div className='list_point'/><div>The Remittv Crypto wallet offers multiple checkout options from: money, Airtime and data Vouchers/Coupons and much more.</div></li>
          <li><div className='list_point'/><div>You can send money home for free by using your earnings to cover any transaction costs.</div></li>
        </ul>

        <p className='bottom_text-pop_up'>Download <span className='yellow'>Remittv</span> App now and you can be part of the Crypto revolution!</p>

        <a
            href="https://play.google.com/store/apps/details?id=com.dotherightthing.remittv"
            target="_blank"
            rel="noopener noreferrer"
          >
          <button className='action'>
            Download now
          </button>
        </a>
      </div>
    </div>
  )
}

export default DownloadPopUp;