import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { sendFeedback, formatPayload } from '../../helpers/index';
import { Link } from 'react-router-dom';
import GPLink from './img-googleplay-btn.svg';
import ASLink from './img-appstore-btn.svg';
import logo from './img-logo-remtv.svg';
import mail from './ic-mail.svg';
import pin from './ic-pin.svg';
import phone from './ic-phone.svg';
import inst from './ic-insta.svg';
import tw from './ic-tw.svg';
import fb from './ic-fb.svg';
import c from './ic-c.svg';
import '../../styles/components/footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: '',
      emailError: '',
      submitError: false,
      isLoading: false,
      isSubmitted: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ emailValue: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      emailError: '',
      submitError: false,
    });

    const regex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/; // eslint-disable-line
    if (this.state.emailValue.length === 0 || !regex.test(this.state.emailValue)) {
      this.setState({ emailError: 'Please enter a valid email' });
      return;
    }

    this.setState({ isLoading: true });
    const payload = formatPayload(
      'New mail subscriber',
      null,
      this.state.emailValue,
      null,
      null,
      this.props.currentPageURL
    );
    const response = await sendFeedback(payload, this.props.subdomain);

    if (!response.request) {
      this.setState({ isLoading: false, submitError: true });
      return;
    } else {
      this.setState({ isLoading: false, isSubmitted: true, emailValue: 'Successfully subscribed' });
    }
  }

  render() {
    return (
      <footer>
        <div className="footer-wrapper">
          <div className="container">
            <div className="subscribe__download_forms">
              <div className="subscribe-form">
                <h1>Subscribe to our Newsletter</h1>
                <p className="error">{this.state.emailError}</p>
                <form onSubmit={this.handleSubmit} className="footer_form">
                  <input
                    className="email_input"
                    type="text"
                    placeholder="Enter your email address"
                    value={this.state.emailValue}
                    onChange={this.handleEmailChange}
                    error={this.state.emailError}
                  />
                  <button onClick={this.handleSubmit} className="submit-button">
                    Subscribe
                  </button>
                </form>
              </div>
              <div className="vertical-hr" />
              <div className="download-form">
                <h1>Download the app</h1>{' '}
                <div className="images">
                  <a href="https://play.google.com/store/apps/details?id=com.dotherightthing.remittv">
                    <img src={GPLink} alt="" />
                  </a>
                  <Link to="/coming">
                    <img src={ASLink} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="hr" />
            <div className="row">
              <div className="column">
                <img src={logo} alt="" />
              </div>
              <div className="column">
                <h1>About us</h1>{' '}
                <Link to="/terms" className="column-link">
                  <h2>Terms & Conditions</h2>
                </Link>
                <Link to="/policy" className="column-link">
                  <h2>Privacy Policy</h2>
                </Link>
              </div>
              <div className="column">
                <h1>Contact us</h1>
                <h2>
                  <img src={mail} alt="" />
                  support@remittv.com
                </h2>
                <h2>
                  <img src={pin} alt="" />
                  Crompton Fold, The Street, Pleshey, CM3 1HE
                </h2>
                <h2>
                  <img src={phone} alt="" />
                  +44 7734556408
                </h2>
              </div>
              <div className="column">
                <h1>Get in touch</h1>
                <div className="vertical-column">
                  <a href="https://www.facebook.com/goremittv/">
                    <img src={fb} alt="" />
                  </a>
                  <a href="https://twitter.com/goremittv">
                    <img src={tw} alt="" />
                  </a>
                  <a href="https://www.instagram.com/goremittv/">
                    <img src={inst} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="copyrighting-box">
              <div className="select">
                <select>
                  <option value="english">English</option>
                </select>
              </div>
              <div className="copyrighting">
                <img src={c} alt="" /> <h1>Remittv 2020</h1>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  currentPageURL: PropTypes.string,
};

export default Footer;
