import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader';
import Details from '../../components/details';
import MostPopular from '../../components/most-popular';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotFound from '../../components/notFound';
import DownloadPopUp from '../../components/downloadPopUp';
import '../../styles/containers/trends-details.css';

const TrendDetails = (props) => {
  const { state } = props.location;
  const { id } = props.match.params;

  const [error, setError] = useState(false);
  const [video, setVideo] = useState(state);
  const [mostPopular, setMostPopular] = useState([]);
  const [loadingVideo, setLoadingVideo] = useState(true);
  const [loadingMostPopular, setLoadingMostPopular] = useState(true);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [videoCount, setVideoCount] = useState(localStorage.getItem('videoCount'));
  const [showCount, setShowCount] = useState(localStorage.getItem('showCount'))

  useEffect(() => {
    const newCount = +videoCount + 1;
    setVideoCount(newCount);
    localStorage.setItem('videoCount', newCount);

    if(showCount === null) {
      localStorage.setItem('showCount', 2);
      setShowCount(2)
    }
    
    if(newCount === +showCount) {
      setTimeout(() => {
        setDownloadOpen(true);
      }, 1000)
      localStorage.setItem('videoCount', 0);
      localStorage.setItem('showCount', +showCount+1);
    }
  }, [])


  window.scrollTo(0, 0);
  useEffect(() => {
    if (!video || video.externalVideoId !== id) {
      axios({
        method: 'GET',
        url: `${process.env.REACT_APP_TRENDS_API_URL}/video`,
        headers: { Authorization: `ApiKey ${process.env.REACT_APP_TRENDS_API_AUTHORIZATION}` },
        params: { videoId: id },
      })
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            setVideo(res.data.video);
            setLoadingVideo(false);
          } else {
            setError(true);
          }
        })
        .catch(() => setError(true));
    } else setLoadingVideo(false);

    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TRENDS_API_URL}/search`,
      headers: { Authorization: `ApiKey ${process.env.REACT_APP_TRENDS_API_AUTHORIZATION}` },
      params: { limit: 6, excludeIds: id },
    })
      .then((res) => {
        if (res.data.status === 'SUCCESS') {
          setMostPopular(res.data.videos);
          setLoadingMostPopular(false);
        } else {
          setError(true);
        }
      })
      .catch(() => setError(true));
  }, [video, id]);

  if (error) {
    return <NotFound header={'This video not Found'} text={'Please, back to home page.'} />;
  } else
    return (
      <Fragment>
        {downloadOpen && (
            <DownloadPopUp onClose={() => setDownloadOpen(false)}/>
        )}
        <div className="details-wrapper">
          <div className="top-block">
            <div className="details-container">
              <div className="details-title">
                <Link className="details-title-link" to="/">
                  <ArrowBackIosIcon fontSize={'small'} className="arrow-icon" />
                  <h1 className="text-style-1">
                    Detail Page
                  </h1>
                </Link>
              </div>
              {loadingVideo ? <Loader /> : <Details video={video} />}
            </div>
          </div>
          <div className="most-popular">
            <div className="details-container">
              <div className="most-popular-title">
                <h1 className="text-style-3">Most popular</h1>
              </div>
              <div className="most-popular-video-block">
                {loadingMostPopular ? <Loader /> : <MostPopular videos={mostPopular} />}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
};

export default TrendDetails;
