import React from 'react';
import { Link } from 'react-router-dom';
import logo from './img-logo.svg';
import menu from './ic-menu.svg';
import googlePley from './google_play_badge.svg' 
import '../../styles/components/header.css';

const Header = (props) => {
  return (
    <header>
      <div className="container">
        <div className="items">
          <div className="items__logo">
            <button className="menu-button" onClick={props.click}>
              <img src={menu} alt="Menu-Button" />
            </button>
            <Link className="logo" to="/">
              <img src={logo} alt="Remittv" />
            </Link>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.dotherightthing.remittv"
            target="_blank"
            rel="noopener noreferrer"
            className="download-button"
          >
            <img src={googlePley} alt="google-play_link" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
