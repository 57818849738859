import React, { useState, useRef, useCallback } from 'react';
import TrendsVideos from '../../components/trends-videos';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader';
import { useVideoSearch } from '../../helpers/index.js';
import check from './check.svg';
import NotFound from '../../components/notFound';

import '../../styles/containers/trends.css';

export default function Trends() {
  const [pageNumber, setPageNumber] = useState(1);
  const [category, setCategory] = useState('');

  const { loading, error, videos, hasMore } = useVideoSearch(pageNumber, category);

  const observer = useRef();
  const lastVideoElement = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore)
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const list = [
    { name: 'School', key: 'school' },
    { name: 'Short Films', key: 'shortfilms' },
    { name: 'Video Games', key: 'videogames' },
    { name: 'Webcam', key: 'webcam' },
    { name: 'Fun', key: 'fun' },
    { name: 'Auto', key: 'auto' },
    { name: 'Music', key: 'music' },
    { name: 'Travel', key: 'travel' },
    { name: 'Sport', key: 'sport' },
    { name: 'Lifestyle', key: 'lifestyle' },
    { name: 'Creation', key: 'creation' },
    { name: 'Tech', key: 'tech' },
    { name: 'TV', key: 'tv' },
    { name: 'News', key: 'news' },
    { name: 'People', key: 'people' },
    { name: 'Animals', key: 'animals' },
  ];

  const MenuItem = ({ text, selected }) => {
    return <div className={`menu-item ${selected ? 'active' : ''}`}>{text}</div>;
  };

  const Menu = (list, selected) =>
    list.map((el) => {
      const { name, key } = el;

      return <MenuItem text={name} key={key} selected={selected} />;
    });

  const menu = Menu(list, category);

  const onSelect = (key) => {
    setCategory(key);
  };

  return (
    <div className="trends-wrapper">
      <div className="content">
        <div className="page-title">
          <h1 className="trending">
            Become a Creator and Influencer!
            <br/>
            Earn crypto from your video content right now!
          </h1>
          <p className='sub-title'>Crypto with purpose.</p>
        </div>
        
        <ScrollMenu data={menu} selected={category} onSelect={onSelect} />
        <div className="videos-wrapper">
          <div className="container">
            <div className="trends-column">
              {videos.map((video, index) => {
                if (videos.length === index + 1) {
                  return (
                    <Link
                      className="item"
                      key={video.externalVideoId}
                      to={{ pathname: `/details/${video.externalVideoId}`, state: video }}
                      ref={lastVideoElement}
                    >
                      <TrendsVideos video={video} />
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      className="item"
                      key={video.externalVideoId}
                      to={{ pathname: `/details/${video.externalVideoId}`, state: video }}
                    >
                      <TrendsVideos video={video} />
                    </Link>
                  );
                }
              })}
              {loading && <Loader />}
              {!hasMore && (
                <h1 className="thats-all">
                  Thats All <img src={check} alt={'check'}></img>
                </h1>
              )}
              {error && (
                <NotFound header={'Something went wrong'} text={'Please, back to home page.'} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
