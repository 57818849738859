import React, { Component } from 'react';
import okey from './okey.svg'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { sendFeedback, formatPayload } from '../../helpers/index';
import '../../styles/containers/contact.css';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameValue: '',
      nameError: '',
      emailValue: '',
      emailError: '',
      phoneValue: '',
      phoneError: '',
      feedbackValue: '',
      feedbackError: '',
      submitError: false,
      isLoading: false,
      isSubmitted: false,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ emailValue: event.target.value });
  }

  handleNameChange(event) {
    this.setState({ nameValue: event.target.value });
  }

  handlePhoneChange(event) {
    this.setState({ phoneValue: event.target.value });
  }

  handleFeedbackChange(event) {
    this.setState({ feedbackValue: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    this.setState({
      nameError: '',
      feedbackError: '',
      emailError: '',
      phoneError: '',
      submitError: false,
    });

    if (this.state.nameValue.length === 0) {
      this.setState({ nameError: 'Please add any Name' });
      return;
    }

    const regex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/; // eslint-disable-line
    if (this.state.emailValue.length === 0 || !regex.test(this.state.emailValue)) {
      this.setState({ emailError: 'Please enter a valid email' });
      return;
    }

    if (this.state.phoneValue.length === 0) {
      this.setState({ phoneError: 'Please add any Phone number' });
      return;
    }

    if (this.state.feedbackValue.length === 0) {
      this.setState({ feedbackError: 'Please add some message' });
      return;
    }

    this.setState({ isLoading: true });
    const payload = formatPayload(
      null,
      this.state.nameValue,
      this.state.emailValue,
      this.state.phoneValue,
      this.state.feedbackValue,
      this.props.currentPageURL
    );
    const response = await sendFeedback(payload, this.props.subdomain);

    if (!response.request) {
      this.setState({ isLoading: false, submitError: true });
      return;
    }

    this.setState({ isLoading: false, isSubmitted: true });
  }

  render() {
    if (this.state.isSubmitted)
      return (
        <div className="feedback-form">
          <h1>Thank you, we have got your inquiry, our team will contact you!</h1>
          <img src={okey} alt=''/>
        </div>
      );
    else
      return (
        <div className="feedback-form">
          <h1>Feedback form</h1>
          <TextField
            id="name"
            name="name"
            className="contact-form-input"
            label={this.state.nameError ? this.state.nameError : 'Name'}
            placeholder="John Doe"
            style={{ marginTop: 24 }}
            value={this.state.nameValue}
            onChange={this.handleNameChange}
            error={this.state.nameError}
          />
          <TextField
            id="email"
            name="email"
            className="contact-form-input"
            label={this.state.emailError ? this.state.emailError : 'Email'}
            placeholder="JohnDoe@gmail.com"
            style={{ marginTop: 24 }}
            value={this.state.emailValue}
            onChange={this.handleEmailChange}
            error={this.state.emailError}
            multiline
          />
          <TextField
            id="phone"
            name="phone"
            className="contact-form-input"
            label={this.state.phoneError ? this.state.phoneError : 'Phone'}
            value={this.state.phoneValue}
            style={{ marginTop: 24 }}
            onChange={this.handlePhoneChange}
            error={this.state.phoneError}
          />
          <TextField
            id="outlined-multiline-static"
            label={this.state.feedbackError ? this.state.feedbackError : 'Message'}
            multiline
            rows={4}
            className='contact-form-message'
            style={{ marginTop: 24 }}
            variant="outlined"
            value={this.state.feedbackValue}
            onChange={this.handleFeedbackChange}
            error={this.state.feedbackError}
          />
          <button onClick={this.handleSubmit} className="send-button">
            {this.state.isLoading ? 'Sending feedback' : 'Send'}
          </button>
        </div>
      );
  }
}

ContactForm.propTypes = {
  subdomain: PropTypes.string,
  feedbackSubject: PropTypes.string,
  currentPageURL: PropTypes.string,
};

export default ContactForm;
