/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../styles/containers/terms.css';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 1120,
  },
});

const Policy = () => {
  const classes = useStyles();
  return (
    <div className="terms-wrapper">
      <div className={classes.root}>
        <Typography variant="body1" paragraph gutterBottom>
          <p>
            <span>If you are a user having your usual residence in the US</span>
            <span>, this&nbsp;</span>
            <a href="https://www.tiktok.com/legal/privacy-policy?lang=en#privacy-us">
              <span>Privacy Policy</span>
            </a>
            <span>&nbsp;shall apply.</span>&nbsp;
          </p>
          <p>
            <span>If you are a user having your usual residence in the EEA and Switzerland</span>
            <span>, this&nbsp;</span>
            <a href="https://www.tiktok.com/legal/privacy-policy?lang=en#privacy-eea">
              <span>Privacy Policy</span>
            </a>
            <span>&nbsp;shall apply.</span>&nbsp;
          </p>
          <p>
            <span>If you are not in the US, EEA or Switzerland</span>
            <span>, this&nbsp;</span>
            <a href="https://www.tiktok.com/legal/privacy-policy?lang=en#privacy-row">
              <span>Privacy Policy</span>
            </a>
            <span>&nbsp;shall apply.&nbsp;</span>
            <span>
              There may also be jurisdiction specific provisions for certain countries or regions.
              Please refer to your local privacy policy for more information.
            </span>
            &nbsp;
          </p>
          <h1>
            <br />
            Privacy Policy
          </h1>
          <p>
            <span>If you are a user having your usual residence in the US</span>
          </p>
          <p>
            <span>Last update: January 1, 2020.</span>&nbsp;
          </p>
          <p>
            <span>Welcome to&nbsp;</span>
            <span>Remit TV</span>
            <span>&nbsp;(the “Platform”). The Platform is provided and controlled by&nbsp;</span>
            <span>Remit TV</span>
            <span>. (“</span>
            <span>Remit TV</span>
            <span>
              ”, “we” or “us”). We are committed to protecting and respecting your privacy. This
              Privacy Policy covers the experience we provide for users age 13 and over on our
              Platform. For information about our under-13 experience (“Children’s Platform”) and
              our practices in the United States regarding children’s privacy, please refer to
              our&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/privacy-policy-for-younger-users?lang=en">
              <span>Privacy Policy for Younger Users</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <p>
            <span>
              Capitalized terms that are not defined in this policy have the meaning given to them
              in the&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-us">
              <span>Terms of Service</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <h2>
            What information do we collect?<a id="calibre_link-3"></a>
            <a id="calibre_link-4"></a>
          </h2>
          <p>
            <span>
              We collect information when you create an account and use the Platform. We also
              collect information you share with us from third-party social network providers, and
              technical and behavioral information about your use of&nbsp;
            </span>
            <span>
              the Platform. We also collect information contained in the messages you send through
              our Platform and information from your phone book, if you grant us access to your
              phone book on your mobile device.&nbsp; More information about the categories and
              sources of information is provided below.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Information you choose to provide</span>
            <a id="calibre_link-5"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              For certain activities, such as when you register, upload content to the Platform, or
              contact us directly, you may provide some or all of the following information:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  Registration information, such as age, username and password, language, and email
                  or phone number
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Profile information, such as name, social media account information, and profile
                  image
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  User-generated content, including comments, photographs, videos, and virtual item
                  videos that you choose to upload or broadcast on the Platform (“User Content”)
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Payment information, such as PayPal or other third-party payment information
                  (where required for the purpose of payment)
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Your phone and social network contacts, with your permission. If you choose to
                  find other users through your phone contacts, we will access and collect the names
                  and phone numbers and match that information against existing users of the
                  Platform. If you choose to find other users through your social network contacts,
                  we will collect your public profile information as well as names and profiles of
                  your social contacts
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>Your opt-in choices and communication preferences</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>Information to verify an account&nbsp;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>Information in correspondence you send to us</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Information you share through surveys or your participation in challenges,
                  sweepstakes, or contests such as your gender, age, likeness, and preferences.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <h3>
            <span>Information we obtain from other sources</span>
            <a id="calibre_link-7"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We may receive the information described in this Privacy Policy from other sources,
              such as:
            </span>
            &nbsp;
          </p>
          <p>
            <span>Social Media.&nbsp;</span>
            <span>
              if you choose to link or sign up using your social network (such as Facebook, Twitter,
              Instagram, or Google), we may collect information from these social media services,
              including your contact lists for these services and information relating to your use
              of the Platform in relation to these services.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Third-Party Services.&nbsp;</span>
            <span>
              We may collect information about you from third-party services, such as advertising
              partners and analytics providers.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Others Users of the Platform.&nbsp;</span>
            <span>
              Sometimes other users of the Platform may provide us information about you, including
              through customer service inquiries.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Other Sources.&nbsp;</span>
            <span>
              We may collect information about you from other publicly available sources.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Information we collect automatically</span>
            <a id="calibre_link-8"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We automatically collect certain information from you when you use the Platform,
              including internet or other network activity information such as your IP address,
              geolocation-related data (as described below), unique device identifiers, browsing and
              search history (including content you have viewed in the Platform), and Cookies (as
              defined below).
            </span>
            &nbsp;
          </p>
          <p>
            <span>Usage Information</span>&nbsp;
          </p>
          <p>
            <span>
              We collect information regarding your use of the Platform and any other User Content
              that you generate through and broadcast on our Platform. We also link your subscriber
              information with your activity on our Platform across all your devices using your
              email, phone number, or similar information.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Device Information&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We collect information about the device you use to access the Platform, including your
              IP address, unique device identifiers, model of your device, your mobile carrier, time
              zone setting, screen resolution, operating system, app and file names and types,
              keystroke patterns or rhythms, and platform.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Location data</span>&nbsp;
          </p>
          <p>
            <span>
              We collect information about your location, including location information based on
              your SIM card and/or IP address. With your permission, we may also collect Global
              Positioning System (GPS) data.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Messages</span>&nbsp;
          </p>
          <p>
            <span>
              We collect and process, which includes scanning and analyzing, information you provide
              in the context of composing, sending, or receiving messages through the Platform’s
              messaging functionality. That information includes the content of the message and
              information about when the message has been sent, received and/or read, as well as the
              participants of the communication. Please be aware that messages sent to other users
              of the Platform will be accessible by those users and that we are not responsible for
              the manner in which those users use or disclose messages.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Metadata</span>&nbsp;
          </p>
          <p>
            <span>
              When you upload User Content, you automatically upload certain metadata that is
              connected to the User Content. Metadata describes other data and&nbsp;
            </span>
            <span>
              provides information about your User Content that will not always be evident to the
              viewer. In connection with your User Content the metadata can describe how, when, and
              by whom the piece of User Content was collected and how that content is formatted. It
              also includes information, such as your account name, that enables other users to
              trace back the User Content to your user account. Additionally, metadata will consist
              of data that you chose to provide with your User Content, e.g. any hashtags used to
              mark keywords to the video and captions.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Cookies</span>&nbsp;
          </p>
          <p>
            <span>
              We and our service providers and business partners use cookies and other similar
              technologies (e.g. web beacons, flash cookies, etc.) (“Cookies”) to automatically
              collect information, measure and analyze which web pages you click on and how you use
              the Platform, enhance your experience using the Platform, improve the Platform, and
              provide you with targeted advertising on the Platform and elsewhere across your
              different devices. Cookies are small files which, when placed on your device, enable
              the Platform to provide certain features and functionality. Web beacons are very small
              images or small pieces of data embedded in images, also known as “pixel tags” or
              “clear GIFs,” that can recognize Cookies, the time and date a page is viewed, a
              description of the page where the pixel tag is placed, and similar information from
              your computer or device. To learn how to disable Cookies, see the “Your choices”
              section below.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              Additionally, we allow these service providers and business partners to collect
              information about your online activities through Cookies. We and our service providers
              and business partners link your contact or subscriber information with your activity
              on our Platform across all your devices, using your email or other log-in or device
              information. Our service providers and business partners may use this information to
              display advertisements on our Platform and elsewhere online and across your devices
              tailored to your interests, preferences, and characteristics. We are not responsible
              for the privacy practices of these service providers and business partners, and
              the&nbsp;
            </span>
            <span>
              information practices of these service providers and business partners are not covered
              by this Privacy Policy.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We may aggregate or de-identify the information described above.&nbsp; Aggregated or
              de-identified data is not subject to this Privacy Policy.
            </span>
            &nbsp;
          </p>
          <h2>
            How we use your information<a id="calibre_link-9"></a>
            <a id="calibre_link-10"></a>
          </h2>
          <p>
            <span>As explained below, we use your information to fulfil and enforce our&nbsp;</span>
            <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-us">
              <span>Terms of Service</span>
            </a>
            <span>
              , to improve and administer the Platform, and to allow you to use its functionalities.
              We may also use your information to, among other things, show you suggestions, promote
              the Platform, and customize your ad experience.
            </span>
            &nbsp;
          </p>
          <p>
            <span>We generally use the information we collect:</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  to fulfil requests for products, services, Platform functionality, support and
                  information for internal operations, including troubleshooting, data analysis,
                  testing, research, statistical, and survey purposes and to solicit your feedback
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to customize the content you see when you use the Platform. For example, we may
                  provide you with services based on the country settings you have chosen or show
                  you content that is similar to content that you liked or interacted with
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to send promotional materials from us or on behalf of our affiliates and trusted
                  third parties
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to improve and develop our Platform and conduct product development</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to measure and understand the effectiveness of the advertising we serve to you and
                  others and to deliver targeted advertising
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to make suggestions and provide a customized ad experience</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to support the social functions of the Platform, including to permit you and other
                  users to connect with each other through the Platform and for you and other users
                  to share, download, and otherwise interact with User Content posted through the
                  Platform
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to use User Content as part of our advertising and marketing campaigns to promote
                  the Platform
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to understand how you use the Platform, including across your devices</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to infer additional information about you, such as your age, gender, and interests
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to help us detect abuse, fraud, and illegal activity on the Platform</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to ensure that you are old enough to use the Platform (as required by law)
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to communicate with you, including to notify you about changes in our services
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to announce you as a winner of our contest, sweepstakes, or promotions if
                  permitted by the promotion rule, and to send you any applicable prizes
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to enforce our terms, conditions, and policies</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  consistent with your permissions, to provide you with location-based services,
                  such as advertising and other personalized content
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>to inform our algorithms</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to combine all the information we collect or receive about you for any of the
                  foregoing purposes
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  for any other purposes disclosed to you at the time we collect your information or
                  pursuant to your consent.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <h2>
            How we share your information<a id="calibre_link-11"></a>
            <a id="calibre_link-12"></a>
          </h2>
          <p>
            <span>We are committed to maintaining your trust, and while&nbsp;</span>
            <span>Remit TV</span>
            <span>
              &nbsp;does not sell personal information to third parties, we want you to understand
              when and with whom we may share the information we collect for business
              purposes.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Service Providers and Business Partners</span>
            <a id="calibre_link-13"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We share the categories of personal information listed above with service providers
              and business partners to help us perform business operations and for business
              purposes, including research, payment processing and transaction fulfi
            </span>
            <span>l</span>
            <span>
              lment, database maintenance, administering contests and special offers, technology
              services, deliveries, email deployment, advertising, analytics, measurement, data
              storage and hosting, disaster recovery, search engine optimization, marketing, and
              data processing.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Within Our Corporate Group</span>
            <a id="calibre_link-14"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We may share your information with a parent, subsidiary, or other affiliate of our
              corporate group.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>In Connection with a Sale, Merger, or Other Business Transfer</span>
            <a id="calibre_link-15"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We may share your information in connection with a substantial corporate transaction,
              such as the sale of a website, a merger, consolidation, asset sale, or in the unlikely
              event of bankruptcy.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>For Legal Reasons</span>
            <a id="calibre_link-16"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We may disclose your information to respond to subpoenas, court orders, legal process,
              law enforcement requests, legal claims, or government inquiries, and to protect and
              defend the rights, interests, safety, and security of&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              , the Platform, our affiliates, users, or the public. We may also share your
              information to enforce any terms applicable to the Platform, to exercise or defend any
              legal claims, and comply with any applicable law.
            </span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <h3>
            <span>With Your Consent</span>
            <a id="calibre_link-17"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We may share information for other purposes pursuant to your consent or with your
              further direction.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              If you access third-party services, such as Facebook, Google, or Twitter, to login to
              the Platform or to share information about your usage on the Platform with others,
              these third-party services may be able to collect information about you, including
              information about your activity on the Platform, and they may notify your connections
              on the third-party services about your use of the Platform, in accordance with their
              privacy policies.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              If you choose to engage in public activities on the Platform, you should be aware that
              any information you share may be read, collected, or used by other users. You should
              use caution in disclosing personal information while engaging. We are not responsible
              for the information you choose to submit.
            </span>
            &nbsp;
          </p>
          <h2>
            Your Rights<a id="calibre_link-18"></a>
            <a id="calibre_link-19"></a>
          </h2>
          <p>
            <span>
              You may submit a request to access or delete the information we have collected about
              you by sending your request to us at the email or physical address provided in the
              Contact section at the bottom of this policy. We will respond to your request
              consistent with applicable law and subject to proper verification. And we do not
              discriminate based on the exercise of any privacy rights that you might have.
            </span>
            &nbsp;
          </p>
          <h2>
            Your Choices<a id="calibre_link-20"></a>
            <a id="calibre_link-21"></a>
          </h2>
          <ul>
            <li>
              <p>
                <span>
                  You may be able to refuse or disable Cookies by adjusting your browser settings.
                  Because each browser is different, please consult the instructions provided by
                  your browser. Please note that you may need to take additional steps to refuse or
                  disable certain types of Cookies. For example, due to differences in how browsers
                  and mobile apps function, you may need to take different steps to disable Cookies
                  used for targeted advertising in a browser and to disable targeted advertising for
                  a mobile application, which you may control through your device settings or mobile
                  app permissions. In addition, your&nbsp;
                </span>
                <span>
                  choice to disable cookies is specific to the particular browser or device that you
                  are using when you disable cookies, so you may need to separately disable cookies
                  for each type of browser or device. If you choose to refuse, disable, or delete
                  Cookies, some of the functionality of the Platform may no longer be available to
                  you.&nbsp; Without this information, we are not able to provide you with all the
                  requested services, and any differences in services are related to your
                  information.
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Your mobile device may include a feature that allows you to opt out of some types
                  of targeted advertising ("Limit Ad Tracking" on iOS and "Opt out of Interest-Based
                  Ads" on Android).
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  You can opt out of marketing or advertising emails by utilizing the “unsubscribe”
                  link or mechanism noted in marketing or advertising emails.
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  You can switch off GPS location information functionality on your mobile device if
                  you do not wish to share GPS information.
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  If you have registered for an account you may access, review, and update certain
                  personal information that you have provided to us by logging into your account and
                  using available features and functionalities.
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Some browsers transmit "do-not-track" signals to websites. Because of differences
                  in how browsers incorporate and activate this feature, it is not always clear
                  whether users intend for these signals to be transmitted, or whether they even are
                  aware of them. We currently do not take action in response to these signals.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <h2>
            Security<a id="calibre_link-22"></a>
            <a id="calibre_link-23"></a>
          </h2>
          <p>
            <span>
              We use reasonable measures to help protect information from loss, theft, misuse and
              unauthorized access, disclosure, alteration, and destruction. You should understand
              that no data storage system or transmission of data over the Internet or any other
              public network can be guaranteed to be 100 percent secure. Please note that
              information collected by third parties may not have the same security protections as
              information you submit to us, and we are not responsible for protecting the security
              of such information.
            </span>
            &nbsp;
          </p>
          <h2>
            Children<a id="calibre_link-24"></a>
            <a id="calibre_link-25"></a>
          </h2>
          <p>
            <span>The privacy of users under the age of 1</span>
            <span>2</span>
            <span>&nbsp;(“Younger Users”) is important to us.&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>The Platform otherwise is not directed at children under the age of 1</span>
            <span>2</span>
            <span>
              . If we become aware that personal information has been collected on the Platform from
              a person under the age of 1
            </span>
            <span>2</span>
            <span>
              &nbsp;we will delete this information and terminate the person’s account.&nbsp;
            </span>
            &nbsp;
          </p>
          <h2>
            Other Rights<a id="calibre_link-26"></a>
            <a id="calibre_link-27"></a>
          </h2>
          <h3>
            <span>Sharing for Direct Marketing Purposes (Shine the Light)</span>
            <a id="calibre_link-28"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              If you are a California resident, once a calendar year, you may be entitled to obtain
              information about personal information that we shared, if any, with other businesses
              for their own direct marketing uses. If applicable, this information would include the
              categories of customer information, as well as the names and addresses of those
              businesses with which we shared customer information for the immediately prior
              calendar year.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Content Removal for Users Under 18</span>
            <a id="calibre_link-29"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              Users of the Platform who are California residents and are under 18 years of age may
              request and obtain removal of User Content they posted by contacting us at:&nbsp;
            </span>
            <a href="https://www.remittv.com/support">
              <span>https://www.</span>
              <span>remittv.</span>
              <span>com/</span>
              <span>support</span>
            </a>
            <span>
              . All requests must be labeled "California Removal Request" on the email subject line.
              All requests must provide a description of the User Content you want removed and
              information reasonably sufficient to permit us to locate that User Content. We do not
              accept California Removal Requests via postal mail, telephone, or facsimile. We are
              not responsible for notices that are not labeled or sent properly, and we may not be
              able to respond if you do not provide adequate information. Please note that your
              request does not ensure complete&nbsp;
            </span>
            <span>
              or comprehensive removal of the material. For example, materials that you have posted
              may be republished or reposted by another user or third party.
            </span>
            &nbsp;
          </p>
          <h2>
            Changes<a id="calibre_link-30"></a>
            <a id="calibre_link-31"></a>
          </h2>
          <p>
            <span>
              We may update this Privacy Policy from time to time. When we update the Privacy
              Policy, we will notify you by updating the “Last Updated” date at the top of this
              policy and posting the new Privacy Policy and providing any other notice required by
              applicable law. We recommend that you review the Privacy Policy each time you visit
              the Platform to stay informed of our privacy practices.
            </span>
            &nbsp;
          </p>
          <h2>
            Contact<a id="calibre_link-32"></a>
            <a id="calibre_link-33"></a>
          </h2>
          <p>
            <span>
              Questions, comments and requests regarding this policy should be addressed to:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>Mailing Address:&nbsp;</span>
                <span>Remit TV, Crompton Fold, The Street, Pleshey, England, CM3 1HE</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>Contact us:&nbsp;</span>
                <a href="https://remittv.com/contact">
                  <span>https://</span>
                  <span>remittv</span>
                  <span>.com/</span>
                  <span>contact</span>
                </a>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h1>
            <span>Privacy Policy</span>
            <a id="calibre_link-34"></a>
            <a id="calibre_link-6"></a>
          </h1>
          <p>
            <span>
              (If you are a user having your usual residence in the European Economic Area (EEA) or
              the UK, or Switzerland)
            </span>
            &nbsp;
          </p>
          <p>
            <span>Last updated: July 2020</span>&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <span>W</span>
            <span>elcome to&nbsp;</span>
            <span>Remit TV</span>
            <span>
              (the “Platform”). We are committed to protecting and respecting your privacy and this
              policy sets out the basis on which we process any personal data we collect from you,
              or that you provide to us. Where we refer to “
            </span>
            <span>RemitTV</span>
            <span>”, “we” or “us” in this Privacy Policy, we mean&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>Limited, an&nbsp;</span>
            <span>English</span>
            <span>&nbsp;company and&nbsp;</span>
            <span>Remit TV</span>
            <span>, a UK company.</span>&nbsp;
          </p>
          <h2>
            <span>SUMMARY</span>
            <a id="calibre_link-35"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <h3>
            <span>What information do we collect about you?</span>
            <a id="calibre_link-36"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>We collect a</span>
            <span>
              nd process information you give us when you create an account and use the Platform.
              This includes technical and behavioural information about your use of the Platform. We
              also collect information about you if you download the app and use the Platform
              without creating an account.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How will we use the information about you?</span>
            <a id="calibre_link-37"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We use your information to provide the Platform to you and to improve and administer
              it. In order to provide an effective and dynamic Platform, and where we have
              determined it is in our legitimate interests, we use your information to improve and
              develop the Platform, prevent crime and ensure users’ safety. Where we have your
              consent, we will also use your personal data to serve you targeted advertising and
              promote the Platform.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Who do we share your information with?</span>
            <a id="calibre_link-38"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We share your data with third party service providers who help us to deliver the
              Platform including cloud storage providers. We also share your information with
              business partners, other companies in the same group as&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              , content moderation services, measurement providers, advertisers and analytics
              providers. We may share your information with law enforcement agencies, public
              authorities or with other third parties only where we are legally required to do so or
              if such use is reasonably necessary (for instance, to ensure your or someone else’s
              safety).&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Your Rights</span>
            <a id="calibre_link-39"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We offer you settings to control and manage the personal data we have about you. You
              also have the following rights: you can ask us to delete your data; to change or
              correct your data; to provide a copy of your data and to stop using some or all of
              your data.. You can contact us using the contact information below, and we will review
              your request in accordance with applicable laws.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How long do we keep hold of your information?</span>
            <a id="calibre_link-40"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We retain your information for as long as it is necessary to provide you with the
              service so that we can fulfil our contractual obligations and exercise our rights in
              relation to the information involved. Where we do not need your information in order
              to provide the service to you, we retain it only as long as we have a legitimate
              business purpose in keeping such data or where we are subject to a legal obligation to
              retain the data. We will also retain your data if necessary for legal claims.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How will we notify you of any changes to this Privacy Policy?</span>
            <a id="calibre_link-41"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We will notify all users of any material changes to this policy through a notice on
              our Platform or by other means. We update the “Last Updated” date at the top of this
              policy, which reflects the effective date of the policy. By accessing or using the
              Platform, you acknowledge that you have read this policy and that you understand your
              rights in relation to your personal data and how we will collect, use and process it.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              ***********************************************************************************************
            </span>
            &nbsp;
          </p>
          <h2>
            <span>1. The types of personal data we use</span>
            <a id="calibre_link-42"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>We collect and use the following information about you:</span>&nbsp;
          </p>
          <p>
            <span>Your Profile Information</span>&nbsp;
          </p>
          <p>
            <span>
              You give us information when you register on the Platform, including your username,
              date of birth, email address and/or telephone number, information you disclose in your
              user profile and your photograph or profile video.
            </span>
            &nbsp;
          </p>
          <p>
            <span>User Content and Behavioural Information</span>&nbsp;
          </p>
          <p>
            <span>
              We process the content you generate and view on the Platform, including preferences
              you set (such as choice of language), photographs and videos you upload, comments and
              live-streams you make (“
            </span>
            <span>User Content</span>
            <span>
              ”). We collect information through surveys, challenges and competitions in which you
              participate. We also collect information regarding your use of the Platform, e.g. how
              you engage with the Platform, including how often you use the Platform and how you
              interact with content we show you, the ads you view, videos you watch and problems
              encountered, the content you like, the content you save to “Favourites”, the words you
              search and the users you follow.
            </span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We infer your interests, gender and age for the purpose of personalising content. We
              also infer the interests of our users to better optimise advertising across our
              Platform. If you have consented, we will use this information for the purpose of
              serving personalised advertising.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We review User Content for content moderation purposes to enforce our community
              guidelines, comply with legal obligations, and prevent and respond to abuse, fraud,
              illegal activity and other potentially harmful content on the Platform.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We also process information about your followers, the likes you receive and responses
              to content you upload, for the purposes of personalising your "
            </span>
            <span>Trending</span>
            <span>
              " Feed, promoting your content to other users and exploring whether your profile
              presents opportunities for collaboration.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Messages</span>&nbsp;
          </p>
          <p>
            <span>
              Where local laws allow us to, we collect and process your messages, which includes
              scanning and analysing the information in those messages through the Platform’s
              messaging functionality. That information includes the content of the message and
              information about when the message has been sent, received and/or read, as well as the
              participants in the communication.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We do this to prevent and block spam, prevent and detect crime, safeguard children
              (where this is in the substantial public interest) or to protect the interests of our
              users and other people.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              Please also be aware that messages sent to other users of the Platform will be
              accessible by those users and that we are not responsible for how those users use or
              disclose them.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Pro Accounts&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Information from Third Parties</span>&nbsp;
          </p>
          <p>
            <span>
              You may choose to share certain data with us from third parties or, through your use
              of the Platform, we may collect such third party data automatically.&nbsp;&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Business Partners</span>&nbsp;
          </p>
          <p>
            <span>
              If you choose to register to use the Platform using your social media account details
              (e.g. Facebook, Twitter, Instagram, Google), you will provide us or allow your social
              network to provide us with your username and public profile. We will likewise share
              certain information with the relevant social network such as your app ID, access token
              and the referring URL.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Advertisers and Measurement Partners</span>&nbsp;
          </p>
          <p>
            <span>
              Where you have consented to personalised advertising, we will match your information
              e.g. your mobile advertising ID, where it is provided to us by advertisers and other
              partners, with your&nbsp;
            </span>
            <span>Remit TV&nbsp;</span>
            <span>
              profile to serve you ads. We may also serve you ads based on the information we infer
              from the data these partners provide. You can opt out of this activity at any time via
              your app settings by going to 'Privacy and safety' and then to 'Personalization and
              data' and opting out of ‘Ads based on data received from partners’.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We use information provided by our measurement partners, to understand how you’ve
              interacted with our ad partners’ websites and better assess the effectiveness of the
              advertising on our Platform.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              For more information about how our measurement and analytics providers collect data
              from the Platform, please see our&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/cookie-policy">
              <span>Cookies Policy</span>
            </a>
            <span>.&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Technical Information we collect about you</span>&nbsp;
          </p>
          <p>
            <span>
              We collect certain information from you when you use the Platform including when you
              are using the app without an account. Such information includes your IP address,
              instance IDs (which allow us to determine which devices to deliver messages to),
              mobile carrier, time zone settings, identifier for advertising purposes and the
              version of the app you are using. We will also collect information regarding the
              device you are using to access the Platform such as the model of your device, the
              device system, network type, device ID, your screen resolution and operating system,
              audio settings and connected audio devices. Where you log-in from multiple devices, we
              will be able to use your profile information to identify your activity across devices.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Location</span>&nbsp;
          </p>
          <p>
            <span>
              When you use the Platform on a mobile device, we will collect information about your
              location in order to customise your experience. We infer your approximate location
              based on your IP address. In certain jurisdictions, we may also collect Global
              Positioning System data.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Information about your friends</span>&nbsp;
          </p>
          <p>
            <span>
              You can choose whether to find other users of the Platform using our “Find Friends”
              function and to invite your contacts to join you on the Platform. We will use the
              contact information you have for that person, such as from within your telephone's
              contact list or Facebook friends list, and give you the option to send them either an
              SMS, email or third party message (such as Whatsapp, Facebook Messenger or Twitter)
              inviting them to view your&nbsp;
            </span>
            <span>Remit TV</span>
            <span>&nbsp;profile.&nbsp;&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We use your personal data to find matches based on your contact information and your
              address book. However, your contact information and your address book is private and
              will not be disclosed. We use a technical system to find matches without sending your
              own information or your address book to us in plain text.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              If you want to find other users through your Facebook contacts, you can grant us
              access to your Facebook contact list. We will then collect your public Facebook
              information as well as names and profiles of your Facebook contacts. We will match
              this to registered users and show you any matches so you can follow them.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>In-app purchases&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              &nbsp;Your purchase will be made via your Apple iTunes or Google Play account. We do
              not collect any financial or billing information from you in relation to such a
              transaction. Please review the relevant app store's terms and notices to learn about
              how your data is used. We keep a record of the purchases you make, the time at which
              you make those purchases and the amount spent so that we can credit your account with
              the correct value in coins.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Information you provide to us&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We collect information you provide us in response to a survey. If you respond to
              a&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              &nbsp;survey, your individual responses will be used for the purpose of the survey and
              will be shared with other organisations, as explained to you when you participate in a
              survey. We may also use aggregate data from these surveys in the same way.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We also collect information you provide to us in correspondence.&nbsp;&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Proof of your identity or age</span>&nbsp;
          </p>
          <p>
            <span>
              We sometimes ask you to provide proof of identity or age in order to use certain
              features, such as Livestream or verified accounts, or when you apply for a “Pro
              Account”.
            </span>
            &nbsp;
          </p>
          <h2>
            <span>2. Cookies</span>
            <a id="calibre_link-43"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>Cookies and similar technologies (e.g. pixels and ad tags) (collectively, “</span>
            <span>Cookies</span>
            <span>
              ”) are small files which, when placed on your device, enable us to collect certain
              information, including personal data, from you in order to provide certain features
              and functionality. We and our service providers and business partners use Cookies to
              collect data and recognise you and your device(s) on the Platform and elsewhere across
              your different devices. We do this to better understand the effectiveness of the
              advertising on the Platform and to enhance your user experience. To learn more about
              cookies please see our&nbsp;
            </span>
            <a href="https://www.tiktok.com/eu/cookie-policy/">
              <span>Cookies Policy</span>
            </a>
            <span>.&nbsp;&nbsp;</span>&nbsp;
          </p>
          <h2>
            <span>3. How we use your personal data</span>
            <a id="calibre_link-44"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              We will use the information we collect about you based on the legal grounds described
              below.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              In accordance with, and to perform our contract with you, we will use your information
              to:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>provide the Platform and associated services;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>notify you about changes to our service;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>provide you with user support;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>enforce our terms, conditions and policies;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>administer the Platform including troubleshooting;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  personalise the content you receive and provide you with tailored content that
                  will be of interest to you;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>enable you to share User Content and interact with other users;</span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  enable our messenger service to function if you choose to use it and are 16 or
                  above;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>enable you to participate in the virtual items program; and</span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>communicate with you.</span>&nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              In order to comply with our legal obligations and as necessary to perform tasks in the
              public interest or to protect the vital interests of our users and other people, we
              use your data to help us prevent and respond to abuse, fraud, illegal activity and
              other potentially harmful content on the Platform.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              In accordance with our legitimate interests to provide an effective and dynamic
              Platform, we may use your information to:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  ensure your safety and security, including reviewing User Content, messages and
                  associated metadata for breaches of our&nbsp;
                </span>
                <a href="https://www.tiktok.com/community-guidelines?lang=en">
                  <span>Community Guidelines</span>
                </a>
                <span>&nbsp;and our&nbsp;</span>
                <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-eea">
                  <span>Terms of Service</span>
                </a>
                <span>;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  ensure content is presented in the most effective manner for you and your device;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  understand how people use the Platform so that we can improve, promote and develop
                  it;&nbsp;&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>promote popular topics, hashtags and campaigns on the Platform;</span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  carry out data analysis and test the Platform to ensure its stability and
                  security;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  verify your identity, for example, to enable you to have a ‘verified account’, and
                  your age, for example, to ensure you are old enough to use certain features;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  provide non-personalised advertising, which keeps many of our services free;&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  infer your interests for optimising our advertising offerings, which, where you’ve
                  consented to personalised advertising, may be based on the information our
                  advertising partners provide to us;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>measure the effectiveness of the advertising you see on our Platform;</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  inform our algorithms so we can deliver the most relevant content to you and to
                  prevent crime and misuse of the Platform;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>carry out surveys regarding our services, products and features;&nbsp;</span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>allow you to participate in interactive features of the Platform; and</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  enable you to socialise on the Platform. For example, we may allow other users to
                  identify you via the "Find Friends" function or through their phone contacts or
                  connect you with other users by tracking who you share links with.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              Where we process your information to fulfil our legitimate interests, we conduct a
              balancing test to check that using personal data is really necessary for us to achieve
              our business purpose. When we carry out this balancing test we also take into account
              the privacy rights of our users and put in place appropriate safeguards to protect
              their personal data.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              With your consent, we will use your information to provide you with personalised
              advertising. Please see the sections on
            </span>
            <a href="https://www.tiktok.com/legal/privacy-policy?lang=en#ad-third-parties">
              <span>&nbsp;Advertisers</span>
            </a>
            <span>
              &nbsp;in “Information from Third Parties” for more information. You can control your
              personalised advertising settings at any time via your app settings. Please go to
              'Privacy&nbsp;
            </span>
            <span>
              and safety' and then 'Personalization and data' to manage and control your advertising
              preferences. If you do not consent to personalised advertising, you will still see
              non-personalised advertising on the Platform.
            </span>
            &nbsp;
          </p>
          <h2>
            <span>4. How we share your personal data</span>
            <a id="calibre_link-45"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>We share your data with the following selected third parties:</span>
            &nbsp;
          </p>
          <p>
            <span>Business Partners</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  If you choose to register to use the Platform using your social network account
                  details (e.g. Facebook, Twitter, Instagram, Google), you provide us or allow your
                  social network to provide us with your username and public profile. We will
                  likewise share certain information with the relevant social network such as your
                  app ID, access token and the referring URL.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  Where you opt to share content on social media platforms, the video, username and
                  any text associated with the post will be shared on that platform or, in the case
                  of sharing via instant messaging platforms such as Whatsapp, a link to the content
                  will be shared.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  In order to provide the “Find Friends” functionality with your Facebook contact
                  list, we share data with Facebook to allow this function to work.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Payment Providers</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  If you are 18 or over and choose to buy virtual items we will share data with the
                  relevant payment provider to facilitate this transaction. We share a transaction
                  ID to enable us to identify you and credit your account with the correct value in
                  coins once you have made the payment.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Service Providers</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  We provide information and content to service providers who support our business,
                  such as cloud service providers and providers of content moderation services to
                  ensure that the Platform is a safe and enjoyable place.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Analytics and measurement providers</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  We use analytics and measurement providers to help us improve the Platform
                  including by assisting us with content measurement and following your activity on
                  our Platform across your devices.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  Our third party analytics and measurement providers also help us measure
                  advertising on our Platform and help our advertisers determine whether their
                  advert has been shown on our Platform and how it performed. We share your mobile
                  advertising ID and other device data with measurement companies so that they can
                  link your activity on the Platform with your activity on our advertisers’
                  websites.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Advertisers</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  We only share aggregated user information with advertisers. Aggregated information
                  is information that is grouped together and is not specific to an individual user.
                  This is done to help measure the effectiveness of an advertising campaign by
                  showing advertisers how many users of the Platform have viewed or clicked on an
                  advertisement.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Our Corporate Group</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  We may share your information with other members, subsidiaries, or affiliates of
                  our corporate group where it is necessary to provide the Platform in accordance
                  with the&nbsp;
                </span>
                <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-eea">
                  <span>Terms of Service</span>
                </a>
                <span>.&nbsp;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  We share information to improve and optimise the Platform, including to prevent
                  illegal use and to support users.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Law Enforcement / Legal Obligation</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  We may share your information with law enforcement agencies, public authorities or
                  other third parties if we consider that we are legally required to do so or if
                  such use is reasonably necessary to:
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>comply with a legal process or request;</span>&nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>enforce our&nbsp;</span>
                <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-eea">
                  <span>Terms of Service</span>
                </a>
                <span>
                  &nbsp;and other agreements, policies, and standards, including investigation of
                  any potential violation;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  detect, prevent or otherwise address abuse, fraud, illegal activity or security or
                  technical issues; or
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  protect the rights, property or safety of us, our users, a third party or the
                  public as required or permitted by law (including exchanging information with
                  other companies and organisations for the purposes of fraud protection and credit
                  risk reduction).
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Public Profiles</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  If your profile is public, your content will be visible to anyone on the Platform
                  and may also be accessed or shared by your friends and followers as well as third
                  parties such as search engines, content aggregators and news sites. You can change
                  who can see a video each time you upload a video. You can also change your profile
                  to private by changing your settings to 'Private account' in 'Privacy and safety'
                  settings. If your profile is public, other users can use your content to produce
                  and upload further content, for example, by creating a duet with your video.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Sale or Merger</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>We disclose your information to third parties:</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  in the event that we sell or buy any business or assets (for example, as a result
                  of liquidation, bankruptcy or otherwise). In such transactions, we will disclose
                  your data to the prospective seller or buyer of such business or assets; or
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  if we sell, buy, merge, are acquired by, or partner with other companies or
                  businesses, or sell some or all of our assets. In such transactions, user
                  information may be among the transferred assets.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <h2>
            <span>5. Where we store your personal data</span>
            <a id="calibre_link-46"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              The personal data that we collect from you will be transferred to, and stored at, a
              destination outside of the European Economic Area ("EEA").
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              Where we transfer your personal data to countries outside the EEA, we do so under the
              European Commission’s model contracts for the transfer of personal data to third
              countries (i.e. standard contractual clauses) pursuant to Commission Decision
              2004/915/EC or 2010/87/EU (as appropriate) or in line with any replacement mechanism
              approved under EU law. For a copy of these Standard Contractual Clauses, contact us
              at:&nbsp;
            </span>
            <span>Remit TV</span>
            <a href="https://www.remittv.com/legal/report/privacy">
              <span>https://www.remittv.com/legal/report/privacy</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <h2>
            <span>6. Your Rights</span>
            <a id="calibre_link-47"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              We offer you settings to control and manage the personal data we have about you.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>You have the following rights:</span>&nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  Access Your Data: You can ask us, free of charge, to confirm we process your
                  personal data and for a copy of your personal data.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  Delete Your Data: You can ask us to delete all or some of your personal
                  data.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  Change or Correct Data: You can ask us to change or fix your data. You can also
                  make changes using the in-app controls and settings.&nbsp;&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  Portability: You can ask for a copy of personal data you provided in a machine
                  readable form.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>
                <span>
                  Object or Restrict Use of Data and Withdraw Consent: You can ask us to stop using
                  some or all of your data, e.g. if we have no legal right to keep using it. You can
                  ask us to stop processing your personal data for direct marketing purposes;
                  withdraw your consent or ask us to stop making any automatic individual decisions,
                  including profiling. If you object to such processing, we ask&nbsp;
                </span>
                <span>
                  you to share the reason for your objection in order for us to examine the
                  processing of your personal data and to balance our legitimate interest in
                  processing and your objection to this processing.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              Before we can respond to a request to exercise one or more of the rights listed above,
              you may be required to verify your identity or your account details.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              For information about how to make these requests, you can contact us using the contact
              information below, and we will review your request while considering applicable
              laws.&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              &nbsp;will be responsible for responding to your request within the relevant periods
              provided by law. If necessary to resolve your request,&nbsp;
            </span>
            <span>Remit TV</span>
            <span>&nbsp;will liaise with&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>UK.</span>&nbsp;
          </p>
          <h2>
            <span>7. The security of your personal data</span>
            <a id="calibre_link-48"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              We take steps to ensure that your information is treated securely and in accordance
              with this policy. Unfortunately, the transmission of information via the internet is
              not completely secure. Although we will do our best to protect your personal data, for
              example, by encryption, we cannot guarantee the security of your information
              transmitted via the Platform, which means any transmission is at your own risk.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We have appropriate technical and organisational measures to ensure a level of
              security appropriate to the risk that may be posed to you and other users. We maintain
              these technical and organisational measures and will amend them from time to time to
              improve the overall security of our systems.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We will, from time to time, include links to and from the websites of our partner
              networks, advertisers and affiliates. If you follow a link to any of these websites,
              please note that these websites have their own privacy policies and that we do not
              accept any responsibility or liability for these policies. Please check these policies
              before you submit any information to these websites.&nbsp;
            </span>
            &nbsp;
          </p>
          <h2>
            <span>8. How long we keep your personal data</span>
            <a id="calibre_link-49"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              We retain your information for as long as it is necessary to provide you with the
              service so that we can fulfil our contractual obligations and exercise our rights in
              relation to the information involved. Where we do not need your information in order
              to provide the service to you, we retain it only for so long as we have a legitimate
              business purpose in keeping such data.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              If you ask us to delete your account it will first be placed into a deactivated state
              for 30 days (to allow you to request it to be reinstated), followed by the deletion of
              the account. We will also delete personal data that relates to the in-app messaging
              function within 30 days of you cancelling your user account. Please note that messages
              sent to other users of our service are stored on their devices and cannot be deleted
              by us.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              In each case, there are also occasions where we may need to keep your data for longer
              in accordance with our legal obligations or where it is necessary for legal claims.
            </span>
            &nbsp;
          </p>
          <h2>
            <span>9. Information relating to children</span>
            <a id="calibre_link-50"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>Remit TV&nbsp;</span>
            <span>
              is not directed at children under the age of 13. If you believe that we have personal
              data about or collected from a child under the relevant age, contact us
            </span>
            <span>&nbsp;</span>
            <a href="https://www.remittv.com/">
              <span>https://www.remittv.com/</span>
            </a>
            <span>support</span>&nbsp;
          </p>
          <h2>
            <span>10. Changes</span>
            <a id="calibre_link-51"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>
              We will notify you of any material changes to this policy through a notice provided
              via the Platform or by other means. The “Last Updated” date at the top of this policy
              reflects the effective date of such policy changes.&nbsp;
            </span>
            &nbsp;
          </p>
          <h2>
            <span>11. Who we are and how to contact us</span>
            <a id="calibre_link-52"></a>
            <a id="calibre_link-6"></a>
          </h2>
          <p>
            <span>Remit TV&nbsp;</span>
            <span>
              provide the Platform and associated services, and together process personal data in
              the manner described in this policy and in our&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-eea">
              <span>Terms of Service</span>
            </a>
            <span>.&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              If you have questions or complaints regarding this policy, or if you wish to reach
              the&nbsp;
            </span>
            <span>Remit TV</span>
            <span>’s Data Protection Officer, contact us at:&nbsp;</span>
            <a href="https://wwwremittv.com/">
              <span>https://wwwremittv.com/</span>
            </a>
            <span>support</span>
            <span>. You can also contact us at the following postal address.</span>
            &nbsp;
          </p>
          <p>
            <span>For users in the European Union, European Economic Area and Switzerland</span>
            <span>&nbsp;and United Kingdom</span>
            <span>:</span>
            <span>&nbsp;</span>
            <span>Remit TV, Crompton Fold, The Street, Pleshey. England, CM3 1HE</span>
            &nbsp;
          </p>
          <p>
            <span>
              If contacting us does not resolve your complaint you have the right to complain to
              your local data protection supervisory authority.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h1>
            Privacy Policy<a id="calibre_link-53"></a>
            <a id="calibre_link-2"></a>
          </h1>
          <p>
            <span>(</span>
            <span>If you are not in the US, EEA or Switzerland</span>
            <span>)</span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Last updated: September 2020</span>&nbsp;
          </p>
          <p>&nbsp;</p>
          <p>
            <span>Welcome to&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>(the “</span>
            <span>Platform</span>
            <span>”). The Platform is provided and controlled by&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>Ltd., with its registered address at&nbsp;</span>
            <span>Crompton Fold, The Street, Pleshey, UK, CM3 1HE</span>&nbsp;
          </p>
          <p>
            <span>
              We are committed to protecting and respecting your privacy.&nbsp; This policy explains
              our practices concerning the personal data we collect from you, or that you provide to
              us. If you do not agree with this policy, you should not use the Platform.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              If you have any questions about how we use your personal data, contact us at:&nbsp;
            </span>
            <a href="https://www.Remittv.com/">
              <span>https://www.Remittv.com/</span>
            </a>
            <span>support</span>
            <span>.</span>&nbsp;
          </p>
          <h2>
            SUMMARY<a id="calibre_link-54"></a>
            <a id="calibre_link-55"></a>
          </h2>
          <h3>
            <span>What information do we collect about you?</span>
            <a id="calibre_link-56"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We collect and process information you give us when you create an account and upload
              content to the Platform. This includes technical and behavioural information about
              your use of the Platform.&nbsp; We also collect information about you if you download
              the app and interact with the Platform without creating an account.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How will we use the information about you?</span>
            <a id="calibre_link-57"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We use your information to provide the Platform to you and to improve and administer
              it.&nbsp; We use your information to, among other things, show you suggestions in the
              ‘For You’ feed, improve and develop the Platform and ensure your safety.&nbsp; Where
              appropriate, we will also use your personal information to serve you targeted
              advertising and promote the Platform.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>Who do we share your information with?</span>
            <a id="calibre_link-58"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We share your data with third party service providers who help us to deliver the
              Platform, such as cloud storage providers. We also share your information with
              business partners, other companies in the same group as&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              , content moderation services, measurement providers, advertisers, and analytics
              providers. Where and when required by law, we will share your information with law
              enforcement agencies or regulators, and with third parties pursuant to a legally
              binding court order.
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How long do we keep hold of your information?</span>
            <a id="calibre_link-59"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We retain your information for as long as it is necessary to provide you with the
              service. Where we do not need your information in order to provide the service to you,
              we retain it only as long as we have a legitimate business purpose in keeping such
              data or where we are subject to a legal obligation&nbsp;
            </span>
            <span>
              to retain the data. We will also retain your data if we believe it is or will be
              necessary for the establishment, exercise or defence of legal claims.&nbsp;
            </span>
            &nbsp;
          </p>
          <h3>
            <span>How will we notify you of any changes to this Privacy Policy?</span>
            <a id="calibre_link-60"></a>
            <a id="calibre_link-6"></a>
          </h3>
          <p>
            <span>
              We will generally notify all users of any material changes to this policy through a
              notice on our Platform. However, you should look at this policy regularly to check for
              any changes. We will also update the “Last Updated” date at the top of this policy,
              which reflects the effective date of such policy. By accessing or using the Platform,
              you acknowledge that you have read this policy and that you understand your rights in
              relation to your personal data and how we will collect, use and process it.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              ***********************************************************************************************
            </span>
            &nbsp;
          </p>
          <h2>
            1. The types of personal data we use<a id="calibre_link-61"></a>
            <a id="calibre_link-62"></a>
          </h2>
          <p>
            <span>We collect and use the following information about you:</span>
            &nbsp;
          </p>
          <p>
            <span>Your Profile Information.</span>
            <span>
              &nbsp;You give us information when you register on the Platform, including your
              username, date of birth (where applicable), email address and/or telephone number,
              information you disclose in your user profile, and your photograph or profile
              video.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>User Content and Behavioural Information.</span>
            <span>
              &nbsp;We process the content you generate on the Platform, including preferences you
              set (such as choice of language), photographs and videos you upload and comments you
              make (“
            </span>
            <span>User Content</span>
            <span>
              ”). In order to improve the upload speed of User Content before clicking “Post” to
              confirm the upload, we will provide a preload service to upload audio and video in
              advance. If you cancel or fail to upload the content for other reasons, we will delete
              the associated audio and video from our server. We collect information through
              surveys, challenges and competitions in which you participate. We also collect
              information regarding your use of the Platform,&nbsp;
            </span>
            <span>e.g.</span>
            <span>
              , how you engage with the Platform, including how you interact with content we show to
              you, the ads you view, videos you watch and problems encountered, the content you
              like, the content you save to ‘My Favourites’ and the users you follow.
            </span>
            <span>&nbsp;&nbsp;</span>
            <span>
              We also infer your preferences, including your interests, gender and age for the
              purpose of personalising content. We process information about your followers, the
              likes you&nbsp;
            </span>
            <span>
              receive and responses to content you upload, for the purposes of promoting your
              content to other users and exploring whether your profile presents further
              opportunities for collaboration. Where appropriate, we will also use this information
              for the purpose of serving personalised advertising and to tell you about new services
              and opportunities.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Information from Third Parties.</span>
            <span>
              &nbsp;You may choose to share certain data with us from third parties or through your
              use of the Platform, we may collect such third party data automatically. We have set
              out further detail on the information we receive from third parties below:
            </span>
            &nbsp;
          </p>
          <p>
            <span>Business Partners</span>&nbsp;
          </p>
          <p>
            <span>
              If you choose to register to use the Platform using your social network account
              details (
            </span>
            <span>e.g.</span>
            <span>
              , Facebook, Twitter, Instagram, Google), you will provide us or allow your social
              network to provide us with your username and public profile. We will likewise share
              certain information with your social network such as your app ID, access token and the
              referring URL. For further information on sharing your Facebook contact list with us,
              please see&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/privacy-policy?lang=en#row-find-friends">
              <span>Find other users and invite your friends</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <p>
            <span>Advertisers and Advertising Networks</span>&nbsp;
          </p>
          <p>
            <span>
              We use the information collected from you, and your interaction with the Platform and
              other third party sites, to infer your likely interests to provide you with more
              relevant advertising. This information tells us about websites you've visited, apps
              you've downloaded and purchases you have made so that we can predict what else might
              interest you in the future and assess how effective the advertising on our Platform
              is. We collect this information by the use of Cookies and similar technologies on our
              App and from similar information received from third parties who advertise on our
              Platform and whose sites you visit.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Technical Information we collect about you.</span>
            <span>
              &nbsp;We automatically collect certain information from you when you use the Platform
              including when you are using the App without an account. Such information includes
              your IP address, browsing history (i.e. the content you have viewed on the Platform),
              mobile carrier, time zone settings, identifier for advertising purposes and the
              version of the app you are using. We will also collect information regarding the
              device you are using to access the Platform such as the model of your device, the
              device system, network type, device ID, your&nbsp;
            </span>
            <span>
              screen resolution and operating system. Where you log-in from multiple devices, we
              will be able to use your profile information to identify your activity across
              devices.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Location.</span>
            <span>&nbsp;We use the 'Region' you select in Settings to customise your&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>
              experience except where we provide location-based services, in which case we collect
              GPS (where we have your consent).&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Find other users and invite your friends.&nbsp;</span>
            <span>
              You can choose whether to find other users of the Platform using our 'Find Friends'
              function. This functionality allows you to see which of your friends from either your
              telephone's contact list or Facebook friends list is using the Platform and offers you
              the option to follow them. You can also use this function to invite your contacts to
              join you on the Platform. We will use the contact information you have for that
              person, either from within your telephone's contact list or Facebook friends list and
              give you the option to send them either an SMS, email or third party message (such as
              Whatsapp, Facebook (including Facebook Messenger) or Twitter) inviting them to view
              your&nbsp;
            </span>
            <span>Remit TV&nbsp;</span>
            <span>profile.&nbsp;&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Buy coins.&nbsp;</span>
            <span>
              If you reside in certain jurisdictions that offer in-app coin purchases, please note
              the provisions of our&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/virtual-items?lang=en#virtual-items-row">
              <span>Virtual Items Policy</span>
            </a>
            <span>
              . Your purchase will be made via your Apple iTunes or Google Play account. We do not
              collect any financial or billing information from you in relation to such a
              transaction. Please review the relevant app store's terms and notices in respect of
              the handling of such data. So that we can credit your account with the correct value
              in coins, we keep a record of the purchases you make, the time at which you make those
              purchases and the amount spent.
            </span>
            &nbsp;
          </p>
          <h2>
            2. Cookies<a id="calibre_link-63"></a>
            <a id="calibre_link-64"></a>
          </h2>
          <p>
            <span>
              We and our vendors and service providers use cookies and other similar technologies (
            </span>
            <span>e.g.</span>
            <span>
              , web beacons, flash cookies, etc.) (“Cookies”) to automatically collect information,
              measure and analyze which web pages you click on and how you use the Platform, enhance
              your experience using the Platform, improve our services, and provide you with
              targeted advertising on the Platform and elsewhere across your different devices.
              Cookies are small files which, when placed on your device, enable the Platform to
              provide certain features and functionality. Web beacons are very small images or small
              pieces of data embedded in images, also known as “pixel tags” or “clear GIFs,” that
              can recognize Cookies, the time and date a page is viewed, a description of the page
              where the pixel tag is placed, and similar&nbsp;
            </span>
            <span>
              information from your computer or device. By using the Platform, you consent to our
              use of Cookies.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              Additionally, we allow our business partners, advertising networks, and other
              advertising vendors and service providers (including analytics vendors and service
              providers) to collect information about your online activities through Cookies. We
              link your contact or subscriber information with your activity on our Platform across
              all your devices, using your email or other log-in or device information. These third
              parties may use this information to display advertisements on our Platform and
              elsewhere online tailored to your interests, preferences, and characteristics. We are
              not responsible for the privacy practices of these third parties, and the information
              practices of these third parties are not covered by this Privacy Policy.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              You may be able to refuse or disable Cookies by adjusting your browser settings.
              Because each browser is different, please consult the instructions provided by your
              browser. Please note that you may need to take additional steps to refuse or disable
              certain types of Cookies. For example, due to differences in how browsers and mobile
              apps function, you may need to take different steps to opt out of Cookies used for
              targeted advertising in a browser and to opt out of targeted advertising for a mobile
              application, which you may control through your device settings or mobile app
              permissions. In addition, your opt-out selection is specific to the particular browser
              or device that you are using when you opt out, so you may need to opt-out separately
              for each of browser or device. If you choose to refuse, disable, or delete Cookies,
              some of the functionality of the Platform may no longer be available to you.
            </span>
            &nbsp;
          </p>
          <h2>
            3. How we use your personal data<a id="calibre_link-65"></a>
            <a id="calibre_link-66"></a>
          </h2>
          <p>
            <span>We will use the information we collect about you in the following ways:</span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>notify you about changes to our service;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>provide you with user support;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  personalise the content you receive and provide you with tailored content that
                  will be of interest to you;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>enable you to share User Content and interact with other users;</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  enable our messenger service to function if you choose to use this function;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>enable you to participate in the virtual items program;&nbsp;</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>communicate with you;&nbsp;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to help us detect and combat abuse, harmful activity, fraud, spam, and illegal
                  activity on the Platform;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  ensure your safety and security including reviewing user content, messages and
                  associated metadata for breach of our Community Guidelines and other inappropriate
                  content;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  ensure content is presented in the most effective manner for you and your device;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  improve, promote and develop the Platform and promote popular topics, hashtags and
                  campaigns on the Platform;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  carry out data analysis and test the Platform to ensure its stability and
                  security;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>allow you to participate in interactive features of the Platform;</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to enable you to socialise on the Platform, for example, by allowing other users
                  to identify you via the "Find other friends" function or through their phone
                  contacts;&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to ensure that you are old enough to use our Platform (as required by law).
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>provide you with personalised advertising;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  provide you with location-based services (where those services are available in
                  your jurisdiction);
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>enforce our terms, conditions and policies; and</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>administer the Platform including troubleshooting.</span>&nbsp;
              </p>
            </li>
          </ul>
          <h2>
            4. How we share your personal data<a id="calibre_link-67"></a>
            <a id="calibre_link-68"></a>
          </h2>
          <p>
            <span>We share your data with the following selected third parties:</span>
            &nbsp;
          </p>
          <p>
            <span>Business Partners</span>&nbsp;
          </p>
          <p>
            <span>
              If you choose to register to use the Platform using your social network account
              details (
            </span>
            <span>e.g.</span>
            <span>
              , Facebook, Twitter, Instagram, Google), you will provide us or allow your social
              network to provide us with your username and public profile. We will likewise share
              certain information with the relevant social network such as your app ID, access token
              and the referring URL.&nbsp;&nbsp;
            </span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              Where you opt to share content on social media platforms, the video, username and
              accompanying text will be shared on that platform or, in the case of sharing via
              instant messaging platforms such as Whatsapp, a link to the content will be
              shared.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Payment Providers</span>&nbsp;
          </p>
          <p>
            <span>
              If you choose to Buy Coins, we will share data with the relevant payment provider to
              facilitate this transaction. We share a transaction ID to enable us to identify you
              and credit your account with the correct value in coins once you have made the
              payment.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Service Providers&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We provide information and content to service providers who support our business, such
              as cloud service providers and providers of content moderation services to&nbsp;
            </span>
            <span>ensure that the Platform is a safe and enjoyable place.&nbsp;</span>
            &nbsp;
          </p>
          <p>
            <span>Analytics providers</span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>
              We use analytics providers to help us in the optimisation and improvement of the
              Platform.&nbsp; Our third party analytics providers also help us serve targeted
              adverts
            </span>
            &nbsp;
          </p>
          <p>
            <span>Advertisers and Advertising Networks</span>&nbsp;
          </p>
          <p>
            <span>
              We share information with advertisers and third party measurement companies to show
              how many and which users of the Platform have viewed or clicked on an advertisement.
              We share your device ID with measurement companies so that we can link your activity
              on the Platform with your activity on other websites; we then use this information to
              show you adverts which may be of interest to you.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Our Corporate Group</span>&nbsp;
          </p>
          <p>
            <span>
              We may also share your information with other members, subsidiaries, or affiliates of
              our corporate group, to provide the Platform including improving and optimising the
              Platform, preventing illegal use and supporting users.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Law Enforcement</span>&nbsp;
          </p>
          <p>
            <span>
              We will share your information with law enforcement agencies, public authorities or
              other organisations if legally required to do so, or if such use is reasonably
              necessary to:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>comply with legal obligation, process or request;</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>enforce our&nbsp;</span>
                <a href="https://www.tiktok.com/legal/terms-of-use?lang=en#terms-row">
                  <span>Terms of Service</span>
                </a>
                <span>
                  &nbsp;and other agreements, policies, and standards, including investigation of
                  any potential violation thereof;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  detect, prevent or otherwise address security, fraud or technical issues; or
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  protect the rights, property or safety of us, our users, a third party or the
                  public as required or permitted by law (including exchanging information with
                  other companies and organisations for the purposes of fraud protection and credit
                  risk reduction).
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Public Profiles</span>&nbsp;
          </p>
          <p>
            <span>
              Please note that if your profile is public, your content will be visible to anyone on
              the Platform and may also be accessed or shared by your friends and followers as well
              as third parties such as search engines, content aggregators and news sites. You can
              change who can see a video each time you upload a video. Alternatively, you can change
              your profile to default private by changing your settings to 'Private Account' in
              “Manage my account”&nbsp;
            </span>
            <span>settings</span>
            <span>.</span>
            <span>&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Sale or Merger</span>&nbsp;
          </p>
          <p>
            <span>We will also disclose your information to third parties:</span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  in the event that we sell or buy any business or assets (whether a result of
                  liquidation, bankruptcy or otherwise), in which case we will disclose your data to
                  the prospective seller or buyer of such business or assets; or
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  if we sell, buy, merge, are acquired by, or partner with other companies or
                  businesses, or sell some or all of our assets. In such transactions, user
                  information may be among the transferred assets.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <h2>
            5. Where we store your personal data&nbsp;
            <a id="calibre_link-69"></a>
            <a id="calibre_link-70"></a>
          </h2>
          <p>
            <span>
              The personal data we collect from you may be stored on a server located in Singapore
              or the United States, outside of the country where you live. We maintain major servers
              around the world to bring you our services globally and continuously.&nbsp;
            </span>
            &nbsp;
          </p>
          <h2>
            6. Your Choices<a id="calibre_link-71"></a>
            <a id="calibre_link-72"></a>
          </h2>
          <p>
            <span>
              You can access and edit most of your profile information by signing into&nbsp;
            </span>
            <span>Remit TV</span>
            <span>
              . You can delete the User Content you uploaded. We also provide a number of tools in
              Settings that allow you to control, among others, who can view your videos, send you
              messages, or post comments to your videos. Should you choose to do so, you may delete
              your entire account in Settings. If you have any questions on how to use those tools,
              or want to know about any rights you may have in the country where you live, please
              contact us at:&nbsp;
            </span>
            <a href="https://www.remittv.com/">
              <span>https://www.remittv.com/</span>
            </a>
            <span>support</span>
            <span>.&nbsp;</span>&nbsp;
          </p>
          <h2>
            7. The security of your personal data<a id="calibre_link-73"></a>
            <a id="calibre_link-74"></a>
          </h2>
          <p>
            <span>
              We take steps to ensure that your information is treated securely and in accordance
              with this policy. Unfortunately, the transmission of information via the internet is
              not completely secure. Although we will protect your personal data, for example, by
              encryption, we cannot guarantee the security of your information transmitted via the
              Platform; any transmission is at your own risk.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We have appropriate technical and organizational measures to ensure a level of
              security appropriate to the risk of varying likelihood and severity for the rights and
              freedoms of you and other users. We maintain these technical and organizational
              measures and will amend them from time to time to improve the overall security of our
              systems.
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              We will, from time to time, include links to and from the websites of our partner
              networks, advertisers and affiliates. If you follow a link to any of these websites,
              please note that these websites have their own privacy policies and that we do not
              accept any responsibility or liability for these policies. Please check these policies
              before you submit any information to these websites.
            </span>
            &nbsp;
          </p>
          <h2>
            8. How long we keep your personal data<a id="calibre_link-75"></a>
            <a id="calibre_link-76"></a>
          </h2>
          <p>
            <span>
              We retain your information for as long as it is necessary to provide you with the
              service. Where we do not need your information in order to provide the service to you,
              we retain it only for so long as we have a legitimate business purpose in keeping such
              data. However, there are occasions where we are likely to keep this data for longer in
              accordance with our legal obligations or where it is necessary for the establishment,
              exercise or defence of legal claims.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              After you have terminated your use of our Platform, we store your information in an
              aggregated and anonymised format.
            </span>
            &nbsp;
          </p>
          <h2>
            9. Information relating to children<a id="calibre_link-77"></a>
            <a id="calibre_link-78"></a>
          </h2>
          <p>
            <span>Remit TV</span>
            <span>
              is not directed at children under the age of 13. In certain cases this age may be
              higher due to local regulatory requirements, please see your local privacy policy for
              more information. If you believe that we have personal data about or collected from a
              child under the relevant age, contact us at:&nbsp;
            </span>
            <a href="https://www.tiktok.com/legal/report/privacy">
              <span>https://www.tiktok.com/legal/report/privacy</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <h2>
            10. Complaints<a id="calibre_link-79"></a>
            <a id="calibre_link-80"></a>
          </h2>
          <p>
            <span>
              In the event that you wish to make a complaint about how we process your personal
              data, contact us immediately at:&nbsp;
            </span>
            <a href="https://www.remit.com/support">
              <span>https://www.remit.com/support</span>
            </a>
            <span>.</span>
            <span>&nbsp;We&nbsp;</span>
            <span>
              will endeavour to deal with your request as soon as possible. This is without
              prejudice to your right to launch a claim with the relevant data protection authority.
            </span>
            &nbsp;
          </p>
          <h2>
            11. Changes<a id="calibre_link-81"></a>
            <a id="calibre_link-82"></a>
          </h2>
          <p>
            <span>
              We will generally notify all users of any material changes to this policy, through a
              notice provided via the Platform. However, you should look at this policy regularly to
              check for any changes. We will also update the “Last Updated” date at the top of this
              policy, which reflects the effective date of such policy. Your continued access to or
              use of the Platform after the date of the updated policy constitutes your acceptance
              of the updated policy. If you do not agree to the updated policy, you must stop
              accessing or using the Platform.
            </span>
            &nbsp;
          </p>
          <h2>
            12. Contact<a id="calibre_link-83"></a>
            <a id="calibre_link-84"></a>
          </h2>
          <p>
            <span>
              If you have questions, comments, or requests regarding this policy, contact us
              at:&nbsp;
            </span>
            <a href="https://www.remittv.com/support">
              <span>https://www.remittv.com/support</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <h2>
            Supplemental Terms &ndash; Jurisdiction-Specific
            <a id="calibre_link-85"></a>
            <a id="calibre_link-86"></a>
          </h2>
          <p>
            <span>
              In the event of a conflict between the provisions of the Supplemental Terms &ndash;
              Jurisdiction-Specific that are relevant to your jurisdiction from which you access or
              use the Services, and the rest of policy, the relevant jurisdictions’ Supplemental
              Terms &ndash; Jurisdiction-Specific will supersede and control.
            </span>
            &nbsp;
          </p>
          <p>
            <span>India.&nbsp;</span>
            <span>
              If you are using our services in India, the Platform is provided and controlled by By
            </span>
            <span>&nbsp;Remit TV Remit TV&nbsp;</span>
            <span>
              is our brand for providing and promoting the services. When using these services from
              India, please accordingly read “
            </span>
            <span>Remit TV</span>
            <span>”, “</span>
            <span>we</span>
            <span>” or “</span>
            <span>us</span>
            <span>” in this policy to refer to&nbsp;</span>
            <span>Remit TV</span>
            <span>&nbsp;Limited.&nbsp;&nbsp;&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Indonesia</span>
            <span>.</span>
            <span>
              &nbsp;If you are using our services in Indonesia, the following additional terms
              apply. In the event of any conflict between the following additional terms and the
              provisions of the main body of this policy, the following terms shall apply.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Age, Parental and Guardian Consent.</span>
            <span>&nbsp;</span>
            <span>
              By accessing and/or using this Platform, you represent that you are at least 21 years
              of age or married or not under guardianship. If you are below 21 years old and you are
              not married, or under guardianship:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>you must obtain approval from your parent(s) or legal guardian(s); and</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  your parent(s) or legal guardian(s) are responsible for: (i) all your actions in
                  connection with your access to and use of the Platform; (ii) your compliance with
                  this policy; and (iv) ensuring that any of your participation in the Platform will
                  not, in any event, result in any violation of applicable laws and regulations
                  relating to child protections.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              If you do not have consent from your parent(s) or legal guardian(s) and your parent(s)
              or guardian(s) is not willing to open the account under their name, you must cease
              accessing the Platform.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Your Choices</span>
            <span>.</span>
            <span>&nbsp;You may withdraw your consent to&nbsp;</span>
            <span>Remit TV</span>
            <span>
              's disclosure of personal data to third parties. Upon your request, we will ease to
              display, publish, transmit, disseminate, and/or open the access to your personal data
              to third parties. Please note that by withdrawing your consent to the disclosure
              and/or collection of your personal data, we may not be able to fulf
            </span>
            <span>I</span>
            <span>l your requests and you may not be able to use some of&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>features and functionality.</span>&nbsp;
          </p>
          <p>
            <span>You may request&nbsp;</span>
            <span>Remit TV&nbsp;</span>
            <span>
              to (i) disclose history of personal data that we have collected; and/or (ii) erase and
              dispose your personal data that we have collected on our server. Please note that by
              requesting us to erase and dispose your personal data, you may not be able to use some
              of&nbsp;
            </span>
            <span>Remit TV&nbsp;</span>
            <span>features and functionality.</span>&nbsp;
          </p>
          <p>
            <span>To exercise any of your rights,&nbsp;</span>
            <span>contact us at:&nbsp;</span>
            <a href="https://www.remittv.com/support">
              <span>https://www.remittv.com/support</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <p>
            <span>Notification.</span>
            <span>&nbsp;</span>
            <span>
              In the event of any breach of personal data, we will notify you and provide you with
              information regarding such breach of personal data.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Data retention</span>
            <span>.&nbsp;</span>
            <span>
              We retain your information for as long as it is necessary to provide you with the
              service. Where we do not need your information in order to provide the service to you,
              we retain it only for so long as we have a&nbsp;
            </span>
            <span>
              legitimate business purpose in keeping such data. However, there are occasions where
              we are likely to keep this data for five (5) years (or longer if required) in
              accordance with our legal obligations or where it is necessary for the establishment,
              exercise or defence of legal claims.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>
              After you have terminated your use of our Platform and the five (5) years retention
              period has lapsed, we store your information in an aggregated and anonymised format.
              Non-personally identifiable information may be retained indefinitely for analytics.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Philippines.</span>
            <span>
              &nbsp;If you are using our services in Philippines, the following additional terms
              shall apply pursuant to the relevant laws, rules and regulations and issuances by the
              Philippines National Privacy Commission (“NPC”) on data privacy.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Your Rights</span>
            <span>.&nbsp;</span>
            <span>
              To the extent that the relevant laws, rules and regulations on data privacy recognize
              your rights and freedoms as data subjects, you shall have the right to information,
              object, access, rectification, erasure or blocking, lodge complaints before the NPC,
              damages and data portability. Before we can respond to a request to exercise one or
              more of the rights listed above, you may be required to verify your identity or your
              account details.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Last Updated:</span>
            <span>&nbsp;</span>
            <span>
              This Supplementary Terms of this Policy was last updated on February 21, 2020 and is
              effective on the same date.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Turkey.</span>
            <span>&nbsp;</span>
            <span>
              If you are using our services in Turkey, the following additional terms apply. In the
              event of any conflict between the following additional terms and the provisions of the
              main body of this policy, the following terms shall prevail.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Purposes for the processing your personal data.&nbsp;</span>
            <span>
              Our purposes for processing your personal data categories as mentioned under “1. The
              types of information we collect” are; (i) complying with our legal obligations, (ii)
              fulfilling our obligations stemming from the Terms and Conditions, (iii) processing
              for all legitimate purposes including providing you the services set forth in the
              Terms and Conditions, (iv) complying with the retaining, reporting and notifying
              obligations set forth in the legislation and determined by the relevant regulatory
              authorities and other authorities; and all other obligations provided in the laws, (v)
              retaining your personal data based on the statute of limitations set out in the
              relevant laws and (vi) processing of your personal data which are already made public.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Your rights.</span>
            <span>&nbsp;</span>
            <span>
              According to Article 11 of the Data Protection Law, you are entitled to request the
              following with respect to your personal data by submitting a written request:&nbsp;
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>to learn whether the personal data relating to you are being processed,</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>if it is processed, to request information with regard to processing,</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to learn purposes of the processing and whether they are used for such purpose or
                  not;
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to know the third persons within or outside the country to whom the personal data
                  are transferred,
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to request correction of the personal data if the data is processed incompletely
                  or inaccurately,
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to request deletion or destruction of the personal data under the conditions set
                  forth in Article 7 of the Data Protection Law,
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to request notifying third persons to whom the personal data are transferred,
                  about the processes completed within the scope of the foregoing two items,
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to object to negative consequences about you that are concluded as a result of
                  analysis of the processed personal data exclusively by automatic means,
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  to claim indemnification if the you suffered damage due to illegal processing of
                  your personal data.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>You may exercise your rights listed above by contacting us at:&nbsp;</span>
            <span>o</span>
            <a href="https://www,remittv.com/support">
              <span>https://www,remittv.com/support</span>
            </a>
            <span>.</span>&nbsp;
          </p>
          <p>
            <span>
              The requests found in your application will be resolved as soon as possible according
              to the nature of your request and within thirty days at the latest free of charge.
              However, if your request incurs additional cost to our company, then you may be
              charged over the tariff fee determined by the Turkish Personal Data Protection Board.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Language.</span>
            <span>&nbsp;</span>
            <span>
              This policy is written in the English language and Turkish language. In the event of
              any inconsistency or different interpretation between the English text and Turkish
              text, the relevant Turkish text shall prevail.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Vietnam.&nbsp;</span>
            <span>
              If you are using our services in Vietnam, the following additional terms apply. In the
              event of any conflict between the following additional terms and the provisions of the
              main body of this policy, the following terms shall prevail.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Age, Parental and Guardian Consent.</span>
            <span>&nbsp;</span>
            <span>
              By accessing and/or using this Platform, you represent that you are at least 16 years
              of age or not under guardianship. If you are below 16 years old or under guardianship:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>you must obtain approval from your parent(s) or legal guardian(s); and</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  your parent(s) or legal guardian(s) are responsible for: (i) all your actions in
                  connection with your access to and use of the Platform; (ii) your compliance with
                  this policy; and (iii) ensuring that any of your participation in the Platform
                  will not, in any event, result in any violation of applicable laws and regulations
                  relating to child protections.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              If you do not have consent from your parent(s) or legal guardian(s) and your parent(s)
              or legal guardian(s) is not willing to open the account under their name, you must
              cease accessing the Platform.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Thailand.&nbsp;</span>
            <span>
              If you are using our services in Thailand, the following additional terms apply. In
              the event of any conflict between the following additional terms and the provisions of
              the main body of this policy, the following terms shall prevail.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Age, Parental and Guardian Consent.</span>
            <span>
              &nbsp;By accessing and/or using this Platform, you represent that you are at least 20
              years of age and/or legally competent under the applicable laws. If you are below 20
              years old and/or not legally competent under the applicable laws:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>you must obtain approval from your parent(s) or legal guardian(s); and</span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  your parent(s) or legal guardian(s) are responsible for: (i) all your actions in
                  connection with your access to and use of the Platform; (ii) your compliance&nbsp;
                </span>
                <span>
                  with this policy; and (iii) ensuring that any of your participation in the
                  Platform will not, in any event, result in any violation of applicable laws and
                  regulations relating to child protections.&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>
              If you do not have consent from your parent(s) or legal guardian(s) and your parent(s)
              or legal guardian(s) is not willing to open the account under their name, you must
              immediately cease accessing the Platform.
            </span>
            &nbsp;
          </p>
          <p>
            <span>United Arab Emirates</span>
            <span>.&nbsp;</span>
            <span>
              If you are using the Platform in the United Arab Emirates (“UAE”), the following
              additional terms apply. If any conflict arises between the main Privacy Policy and the
              additional terms, the following terms shall prevail:&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>1. How we share your personal data</span>&nbsp;
          </p>
          <p>
            <span>
              In addition to the main text of this policy, by using the Platform in the UAE you
              agree to the terms of this Privacy Policy and consent to our sharing of your personal
              data in line with the main text of this Privacy Policy.&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>2. Governing law and dispute resolution</span>&nbsp;
          </p>
          <p>
            <span>
              By using our Platform in the UAE, this Privacy Policy will be governed by and
              interpreted in accordance with the laws of the UAE. In the event of a dispute arising
              between the parties in connection with this Privacy Policy:
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  where the dispute relates to a claim for a sum within the limits specified by the
                  DIFC Small Claims Tribunal from time to time, then the dispute shall be referred
                  by either party to the said Tribunal; and
                </span>
                &nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>
                  for all other disputes, the parties shall seek settlement of that dispute by
                  mediation in accordance with the Mediation Rules of the DIFC-LCIA Arbitration
                  Centre, which Rules are deemed to be incorporated by reference to this clause. If
                  the dispute is not settled by mediation within 30 days of the commencement of the
                  mediation, or such further period as the parties shall agree in writing, the
                  dispute shall be referred to and finally resolved by arbitration under the
                  Arbitration Rules of the DIFC-LCIA Arbitration Centre, which Rules are deemed to
                  be incorporated by reference to this clause. The language to&nbsp;
                </span>
                <span>
                  be used in the mediation and in the arbitration, shall be English. In any
                  arbitration commenced pursuant to this clause the number of arbitrators shall be
                  one and the arbitration shall be conducted in Dubai.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>Israel.&nbsp;</span>
            <span>
              If you are using our services in Israel, the following additional terms apply.
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>
                  You declare that you are over the age of 18. If you are under the age of 18,
                  please have your parent or legal guardian read this with you. If you are reviewing
                  these terms as the parent/legal guardian of a user who is under the age of 18, you
                  hereby declare that such user is above the age of 13 and that you have read and
                  acknowledged TikTok's Privacy Policy and Terms of Use and agree to the use by your
                  child of &nbsp;the Platform and registration for an account.
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <p>
            <span>South Korea</span>
            <span>.</span>
            <span>
              &nbsp;If you are using our services in South Korea, the following additional terms
              apply. In the event of any conflict between the following additional terms and the
              provisions of the main body of this policy, the following terms shall prevail.
            </span>
            &nbsp;
          </p>
          <ul>
            <li>
              <p>
                <span>Entrustment and/or Overseas Transfer of Personal Data</span>
                <span>.&nbsp;</span>
                <span>
                  We entrust your data to our affiliates, cloud storage providers, IT service
                  providers, and data centers, some of whom are located abroad (See the Korean
                  language Privacy Policy), subject to your consents or notifications to you, if
                  applicable. The entities receiving and processing your data are committed to using
                  and storing personal data in compliance with domestic and international
                  regulations and to taking all available physical and technical measures to protect
                  personal data. You may opt-out of such transfer so long as the transfer is not
                  necessary to provide you with the Service, by contacting us&nbsp;
                </span>
                <a href="mailto:privacy@tiktok.com">
                  <span>here</span>
                </a>
                <span>.</span>&nbsp;
              </p>
            </li>
            <li>
              <p>
                <span>&nbsp;</span>
                <span>
                  Local representative pursuant to the Personal Information Protection Act&nbsp;
                </span>
                &nbsp;
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <ul>
                <li>
                  <p>
                    <span>
                      Please send your inquiries concerning privacy issues in South Korea to
                      our&nbsp;
                    </span>
                    <span>UK Office&nbsp;</span>
                    <span>Address:&nbsp;</span>
                    <span>Crompton Fold, The street, Pleshey, UK, CM3 1HE</span>
                    &nbsp;
                  </p>
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <span>Brazil</span>
            <span>.</span>
            <span>
              &nbsp;If you are using the Platform in Brazil, the following additional terms apply.
              If any conflict arises between the main Privacy Policy and the additional terms, the
              following terms shall prevail:&nbsp;
            </span>
            &nbsp;
          </p>
          <p>
            <span>Parental and Guardian Consent.</span>
            <span>
              &nbsp;If required by Brazilian data protection laws, (i) if you are over the age of 16
              but under the age of 18, you can only use and register for an account with the
              assistance of your parent or legal guardian and you declare and represent that you had
              such assistance to use the Services and to agree to the Policy; (ii) if you are over
              the age of 13 but under the age of 16, you can only use and register for an account
              with the representation of your parent or legal guardian, and you must obtain the
              consent from your parent or legal guardian to the use of the Services and acceptance
              of this Privacy Policy.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Exercise of data protection rights.</span>
            <span>
              &nbsp;Brazilian law provides certain rights to individuals with regard to their
              personal data. Thus, we seek to ensure transparency and access controls in order to
              allow users to benefit from the mentioned rights.&nbsp;
            </span>
            <span>
              <br />
            </span>
            <span>
              We will respond and/or fulfill your requests for the exercise of your rights below,
              according to the applicable law and when applicable, to the Brazilian General Data
              Protection Law - LGPD, once it comes into force:
            </span>
            <span>
              <br />
            </span>
            <span>I. confirmation of whether your data are being processed;</span>
            <span>
              <br />
            </span>
            <span>II. access to your data;</span>
            <span>
              <br />
            </span>
            <span>III. correction of incomplete, inaccurate or outdated data;</span>
            <span>
              <br />
            </span>
            <span>IV. anonymization, blocking or erasure of data;</span>
            <span>
              <br />
            </span>
            <span>V. portability of personal data to a third party;</span>
            <span>
              <br />
            </span>
            <span>VI. object to the processing of personal data;</span>
            <span>
              <br />
            </span>
            <span>VII. information of public and private entities with which we shared data;</span>
            <span>
              <br />
            </span>
            <span>
              VIII. information about the possibility to refuse providing personal data and the
              respective consequences, when applicable;
            </span>
            <span>
              <br />
            </span>
            <span>IX. withdrawal of your consent.&nbsp;</span>&nbsp;
          </p>
          <p>
            <span>Verifying your identity</span>
            <span>
              : For your safety and to allow us to make sure that we do not disclose any of your
              personal data to unauthorized third parties, in order to verify your identity and
              guarantee the adequate exercise of your rights, we may request specific information
              and/or documents from you before we can properly respond to a request received
              concerning your data. All data and documents received from you in the process of
              responding to&nbsp;
            </span>
            <span>
              your requests will be used for the strict purposes of analyzing your request,
              authenticating your identity, and finally responding to your request.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Limitations to your rights</span>
            <span>
              : In certain situations, we may have legitimate reasons not to comply with some of
              your requests. For instance, we may choose not to disclose certain information to you
              when a disclosure could adversely impact our business whenever there is a risk of
              violation to our trade secrets or intellectual property rights. In addition, we may
              refrain from complying with a request for erasure when the maintenance of your data is
              required for complying with legal or regulatory obligations or when such maintenance
              is required to protect our rights and interests in case a dispute arises. Whenever
              this is the case and we are unable to comply with a request you make, we will let you
              know the reasons why we cannot fulfill your request.
            </span>
            <span>
              <br />
            </span>
            <span>
              In case of doubt about your privacy, your rights or how to exercise them, please
              contact us through the form "Contact". If you have any questions about the processing
              of your personal data, we would like to clarify them.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Access Logs</span>
            <span>
              . We keep your application access logs, under confidentiality, in a controlled and
              safe environment, for, at least, 6 months, in order to comply with legal obligations.
            </span>
            &nbsp;
          </p>
          <p>
            <span>Language</span>
            <span>
              . The Policy may have been prepared in the English language and in the Portuguese
              language. If you are a user located in Brazil, you shall refer to the Portuguese
              version, which shall prevail.
            </span>
            &nbsp;
          </p>
          <p>&nbsp;</p>
        </Typography>
      </div>
    </div>
  );
};

export default Policy;
