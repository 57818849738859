import React from 'react';
import { durationTime, uploadTime } from '../../helpers';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';

import '../../styles/containers/trends.css';

const TrendsVideos = ({ video }) => {
  return (
    <div className="video-item">
      <div className="video-container">
        <div className="image">
          <img src={video.thumbnailUrl} alt={'videoImage'} />
        </div>
        <div className="duration-block">{durationTime(video.duration)}</div>
        <div className="category-block">{video.category}</div>
      </div>
      <div className="name_tags">
        <p className="channel-name">
          {video.channelName}
          <DoneIcon className="done-icon" />
        </p>
        <h1 className="tags">{video.tags.slice(0, 5).map((tag) => `#` + tag)}</h1>
      </div>
      <div className="title_desc">
        <h1 className="title">{video.title}</h1>
        <h1 className="desc">{video.description}</h1>
      </div>
      <div className="view_upload">
        <p className="views">
          <VisibilityIcon className="views-icon" />
          {video.totalViews}
        </p>
        <h1 className="upload">{uploadTime(video.uploadTime)}</h1>
      </div>
    </div>
  );
};

export default TrendsVideos;
