import React from 'react';
import ContactForm from '../../components/contact-form';
import small from './background-image.jpg';
import medium from './background-image@2x.jpg';
import large from './background-image@3x.jpg';
import '../../styles/containers/contact.css';

const Contact = () => {
  return (
    <div className="contacts-wrapper">
      <div className="background-img">
        <img
          src={medium}
          alt=""
          srcSet={`${small} 1x, ${medium} 2x, ${large} 3x`}
          className="Background-image"
        />
      </div>
      <div className="contacts-container">
        <h1 className="h1-1">Contact</h1>
        <div className="text-block">
          <h1 className="h1-2">Contact</h1>
          <div className="information">
            <div className="information-item">
              <h2 className="yealow_text">For Partnership, investment and business opportunities : </h2>
              <h3>Tom Shrager tom.shrager@bluepodmedia.com</h3>
            </div>
            <div className="information-item">
              <h2 className="yealow_text">Contact for PR & Marketing : </h2>
              <h3>Tom Shrager tom.shrager@bluepodmedia.com</h3>
            </div>
            <div className="information-item">
              <h2 className="yealow_text">Contact for PR & Marketing :</h2>
              <h3>support@remittv.com</h3>
            </div>
          </div>
        </div>
        <div className="form-block">
          <ContactForm subdomain="remittv" currentPageURL={window.location} />
        </div>
      </div>
    </div>
  );
};

export default Contact;
