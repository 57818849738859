import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Header from './components/header';
// import Home from './containers/home';
import Terms from './containers/terms';
import Trends from './containers/trends';
import TrendDetails from './containers/trends-details';
import Contact from './containers/contact/index';
import ContactMobile from './containers/contact/mobile';
import Policy from './containers/privacy-policy';
import About from './containers/about';
import Footer from './components/footer';
import SideBar from './components/sidebar';
import SideBarBackDrop from './components/sidebar-backdrop';
import NotFound from './components/notFound';
import DownloadPopUp from './components/downloadPopUp';
import Rewards from './containers/rewards';

import './styles/App.css';

export default class App extends Component {
  state = {
    sideBarOpen: false,
    downloadOpen: false,
    firstTime: localStorage.getItem('firstTime'),
  };

  componentDidMount() {
    if(!this.state.firstTime) {
      setTimeout(() => {
        this.openPopUp();
      }, 3000)
      localStorage.setItem('firstTime', 'no');
    }
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideBarOpen: !prevState.sideBarOpen };
    });
  };

  openPopUp = () => {
    this.setState({downloadOpen: !this.state.downloadOpen})
  }

  backdropClickHandler = () => {
    this.setState({ sideBarOpen: false });
  };

  render() {
    let sideBarBackDrop;

    if (this.state.sideBarOpen) {
      sideBarBackDrop = <SideBarBackDrop click={this.backdropClickHandler} />;
    }
    return (
      <Router>
        <div className="wrapper">
          <SideBar show={this.state.sideBarOpen} click={this.backdropClickHandler} />
          {sideBarBackDrop}
          {!window.location.pathname.split('_')[1] ? (
            <div className="header">
              <Header click={this.drawerToggleClickHandler} />
            </div>
          ) : null}
          <div className="content">
            <Switch>
              {/* <Route exact path="/" render={Home} /> */}
              <Route exact path="/" component={Trends} />
              <Route path="/terms" component={Terms} />
              {/* <Route path="/trends" component={Trends} /> */}
              <Route path="/details/:id" component={TrendDetails} />
              <Route path="/rewards" component={Rewards} />
              <Route path="/contact" component={Contact} />
              <Route path="/contact_webview" component={ContactMobile} />
              <Route path='/terms_webview' component={Terms} />
              <Route path="/policy_webview" component={Policy} />
              <Route path="/policy" component={Policy} />
              <Route path="/about" component={About} />
              <Route path="/coming" component={() => (< NotFound header={'Coming soon'} text={'We will add iOS version any time soon...'} /> )}/>
              <Redirect from="/x_details/:id" to="/details/:id" />
              <Route component={() => ( <NotFound header={'Page not Found'} text={'Please back to home page.'} /> )}/>
            </Switch>
          </div>
          {!window.location.pathname.split('_')[1] ? <div className="footer">
            <Footer subdomain="remittv" currentPageURL={window.location} />
          </div> : null}
          {this.state.downloadOpen && (
            <DownloadPopUp onClose={this.openPopUp}/>
          )}
        </div>
      </Router>
    );
  }
}
