import { useEffect, useState } from 'react';
import axios from 'axios';

function durationTime(duration) {
  let res;

  res = new Date(duration).toTimeString();
  res = res.split('GMT')[0];
  res = res.split(':');
  res = res[1] + ':' + res[2];

  return res;
}

function uploadTime(upload) {
  let arr = new Date(upload).toString().split('GMT')[0];

  arr = arr.split(' ');
  let hours = arr[4].split(':');

  return `${arr[0]} ${arr[1]} ${arr[2]} ${hours[0]}:${hours[1]}`;
}

const sendFeedback = async (data, subdomain) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await fetch(`https://${subdomain}.zendesk.com/api/v2/requests.json`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers,
    });
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    return error;
  }
};

const formatPayload = (sub, name, email, phone, feedback, pageURL) => {
  const requester = name ? { name: name } : { name: email };

  let subject = sub ? sub : ``;

  const words = feedback ? feedback.split(' ') : null;
  if (words) {
    const count = words.length > 3 ? 3 : words.length;
    for (let i = 0; i < count; i++) subject += ` ${words[i]}`;
  }

  const comment = sub
    ? {
        body: `${email}
${pageURL}`,
      }
    : {
        body: `${name}
${phone}
${email}
${feedback}
${pageURL}`,
      };

  return {
    request: {
      requester,
      subject,
      comment,
    },
  };
};

function useVideoSearch(pageNumber, category) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [videos, setVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setVideos([]);
    setHasMore(true);
  }, [category]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_TRENDS_API_URL}/search`,
      headers: { Authorization: `ApiKey ${process.env.REACT_APP_TRENDS_API_AUTHORIZATION}` },
      params: { page: pageNumber, limit: 20, category: category },
    })
      .then((res) => {
        if (res.data.status === 'SUCCESS') {
          setVideos((prevVideos) => {
            return [...new Set([...prevVideos, ...res.data.videos])];
          });
          setHasMore(res.data.hasMore);
          setLoading(false);
        } else setError(true);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
      });
  }, [pageNumber, category]);

  return { loading, error, videos, hasMore };
}

export { durationTime, formatPayload, sendFeedback, useVideoSearch, uploadTime };
