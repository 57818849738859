import React from 'react';
import { Link } from 'react-router-dom';
import medium from './Image@2x.png';
import small from './Image.png';
import large from './Image@3x.png';

import '../../styles/containers/notFound.css'

const NotFound = ({ header, text }) => (
  <div className="notFound-wrapper">
    <div className="notFound-wrapper-img">
      <img src={medium} alt="" srcSet={`${small} 1x, ${medium} 2x, ${large} 3x`} />
    </div>
    <div className="notFound-wrapper-text">
      <h1>{header}</h1>
      <p>{text}</p>
    </div>
    <Link to="/" className="notFound-wrapper-link">
      {' '}
      Back to Home{' '}
    </Link>
  </div>
);

export default NotFound;
