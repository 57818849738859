import React from 'react';
import '../../styles/containers/about.css';
import medium from './banner@2x.png';
import small from './banner.png';
import large from './banner@3x.png';
import thBl1 from './third-block-img1.svg';
import thBl2 from './third-block-img2.svg';
import foBl1 from './fourth-block-img1.svg';
import fiBl1 from './fifth-block-img1.svg';
import star from './star.svg';
import users1 from './users.png';

const About = () => {
  return (
    <div>
      {' '}
      <div className="about-wrapper">
        <div className="about-page-title">
          About 
          <span className="yellow-block">{" Remittv"}</span>
        </div>
        <div className="first-block">
          <div className="photo">
            <img
              src={small}
              alt=''
              srcSet={`${small} 1x, ${medium} 2x, ${large} 3x`}
              className="Background-image"
            />
          </div>
          <div className="text">
            <h1>+100 000 videos</h1>
            <p>
              We love your home videos of your family, friends, festivals, weddings, mum cooking,
              sports, holidays, pets and much, much more.
            </p>
            <p>
              We want to show the world the best of you and for you to get rewarded fairly for your
              great work.
            </p>
          </div>
        </div>
        <div className="second-block">
          <div className="vertical-line" />
          <div className="info-block">
            <div className="text">
              <p>Download the app and start watching and creating!</p>
            </div>
            <div className="info">
              <div className="info-item">
                <h1>1074</h1>
                <p>TOTAL MEMBERS</p>
              </div>
              <div className="vertical-ln" />
              <div className="info-item">
                <h1>100 000</h1> <p>TOTAL VIDEOS</p>
              </div>
              <div className="avatars">
                <img src={users1} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="third-block">
          <div className="blog-item">
            <div className="blog-img">
              <img src={thBl2} alt="" />
            </div>
            <div className="blog-text">
              <h1>Remittv gifts you in app coins - called Sukiz!  </h1>
              <p>
                You tip Sukiz to your creator and INSTANTLY! the Sukiz are in your creator account
                at no cost to you!
              </p>
            </div>
          </div>
          <div className="blog-item">
            <div className="blog-img">
              <img src={thBl1} alt="" />
            </div>
            <div className="blog-text">
              <h1>The Remittv wallet!</h1>
              <p>Today we have PAYPAL, PAYTM and 400 mobile operators to checkout your Sukiz.</p>
              <p>
                Soon we will have every digital wallet in the world, so build your Sukiz up and
                checkout when the wallet options are all in the app!
              </p>{' '}
            </div>
          </div>
        </div>
        <div className="fourth-block">
          <div className="star">
            <img src={star} alt="" />
          </div>
          <div className="fourth-block-img-first">
            <img src={foBl1} alt="" />
          </div>
          <div className="text">
            <h1>
              Youtube, Facebook, TIKTOK and Insta all build their service on your great content. Do
              you see any of this revenue? No.
            </h1>
            <p>
              Remittv is an app that enables Diaspora in USA and Europe to send money home, simply
              by watching your familys content. Remittv believe that the revenue earned by our
              creators content should be shared equally and from day1.
            </p>
            <p>
              Remittv brings an ecosystem that is fairer and transparent for communities and
              consumers who should have the chance to earn from their content. Today, you simply
              can't do that with the Silicon Valley corporations, Remittv wants to change this
              dramatically.
            </p>
          </div>
          <div className="fourth-block-img-second">
            <img src={foBl1} alt="" />
          </div>
        </div>
        <div className="fifth-block">
          <div className="star">
            <img src={star} alt="" />
          </div>
          <div className="fifth-block-img">
            <img src={fiBl1} alt="" />
          </div>
          <div className="text">
            <h1>Have fun, spread theword and lets make a better life for all</h1>
            <p>
              It has never been more important that people of colour have a platform that enables
              them to earn and have a better life simply from their content. Submit your content to
              Remittv and help build a society that embraces sharing and the opportunity to send
              and remit money with less fees and more for your family.
            </p>
            <p> Have fun, spread the word and lets make a better life for all</p>
            <p> Remittv team.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
