import React from 'react';
import { Link } from 'react-router-dom';
import { durationTime } from '../../helpers/index.js';
import DoneIcon from '@material-ui/icons/Done';

import '../../styles/containers/trends-details.css';

const MostPopular = ({ videos }) => {
  return videos.map((video) => (
    <Link
      className="most-popular-item"
      key={video.externalVideoId}
      to={{ pathname: `/x_details/${video.externalVideoId}`, state: video }}
    >
      <div className="image-container">
        <div className="img">
          <img src={video.thumbnailUrl} alt="videoImage" />
        </div>
        <div className="duration-block">{durationTime(video.duration)}</div>
      </div>
      <div className="ch_name-tags">
        <p className="channel-name">
          {video.channelName}
          <DoneIcon className="done-icon" />
        </p>
        <h1 className="tags">{video.tags.slice(0, 3).map((tag) => `#` + tag)}</h1>
      </div>
      <div className="title">
        <h1 className="title">{video.title}</h1>
      </div>
    </Link>
  ));
};

export default MostPopular;
