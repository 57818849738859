import React from 'react';
import '../../styles/containers/about.css';
import phone1 from './phone1.png';
import phone2 from './phone2.png';
import phone3 from './phone3.png';
import phone4 from './phone4.png';
import phone5 from './phone5.png';

const Rewards = () => {
  return (
    <div>
      <div className="footer-block">
        <div className="shadow" />
        <div className="footer-container">
          <div className="footer-container-text">
            <h1>Unleash more power with Remittv bonuses</h1>
            <p>
              Remittv wants everyone to win and earn Sukiz and Crypto if they download the app. There are many opportunities to earn more if you take part and become a creator or refer friends and family.
              It is easy to win if you can earn as much as you engage and take part.<br/>
              <br/>
              Good luck and can't wait to be sending you rewards very soon<br/>
              <br/>
              Remittv team. 
            </p>
          </div>
          <div className="footer-images">
            <div className="footer-phone-img">
              <img src={phone1} alt="" />
            </div>
            <div className="footer-phone-img">
              <img src={phone2} alt="" />
            </div>
            <div className="footer-phone-img">
              <img src={phone3} alt="" />
            </div>
            <div className="footer-phone-img">
              <img src={phone4} alt="" />
            </div>
            <div className="footer-phone-img">
              <img src={phone5} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
