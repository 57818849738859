import React from 'react';
import ContactForm from '../../components/contact-form';
import '../../styles/containers/contact.css';

const ContactMobile = () => {
  return (
    <div className="contacts-container">
      <div className="form-block">
        <ContactForm subdomain="remittv" currentPageURL={window.location} />
      </div>
    </div>
  );
};

export default ContactMobile;
