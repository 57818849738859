import React, { useState } from 'react';
import close from './ic-close.svg';
import logo from './img-logo-menu.svg';
import googlePley from './btn-link.svg' 
import { Link } from 'react-router-dom';

import '../../styles/components/sidebar.css';

export default function SideBar(props) {

  const [lenguage, setLenguage] = useState('en');

  let drawerClasses = 'side-bar';
  if (props.show) {
    drawerClasses = 'side-bar open';
  }

  return (
    <nav className={drawerClasses}>
      <div className="side-bar__close-button">
        <button onClick={props.click}>
          <img src={close} alt="" />
        </button>
      </div>
      <Link to="/">
        <div className="side-bar__logo">
          <img src={logo} alt="" />
        </div>
      </Link>
      <ul>
        <li id="bold">
          <Link to="/about">About Remittv</Link>
        </li>
        <li id="bold">
          <Link to="/rewards">Remittv rewards</Link>
        </li>
        <li id="bold">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
      <div className="underline" />
      <ul>
        <li id="regular">
          <Link to="/policy">Privacy policy</Link>
        </li>
        <li id="regular">
          <Link to="/terms">Terms and Conditions</Link>
        </li>
        <li id="regular">
          <a href="/">User agreement</a>
        </li>
      </ul>
      <div className="underline" />
      <div
        className={`lenguage_select ${lenguage ==='en' && 'active_lenguage'}`}
        onClick={() => setLenguage('en')}
      >
        English
      </div>
      <div
        className={`lenguage_select ${lenguage ==='ind' && 'active_lenguage'}`}
        onClick={() => setLenguage('ind')}
      >
        Hindi
      </div>
      <a
        href="https://play.google.com/store/apps/details?id=com.dotherightthing.remittv"
        target="_blank"
        rel="noopener noreferrer"
        className="btn-link"
      >
        <img src={googlePley} alt="google-play_link" />
      </a>
    </nav>
  );
};
